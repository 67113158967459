import { IStorageProvider } from 'unleash-proxy-client';
import { Store } from 'vuex';

export const createFeatureFlagStorageProvider = (
  store: Store<any>
): IStorageProvider => ({
  get(name) {
    return store.state?.featureFlagStorage?.[name];
  },
  save(name, data) {
    return store.dispatch('featureFlagStorage/setItem', { name, data });
  },
});
