import { Context } from '@nuxt/types';
import isEmpty from 'lodash.isempty';
import dayjs from 'dayjs';
import {
  UTM_AVAILABLE_PARAMS,
  UTM_VALUES_LOCAL_STORAGE_KEY,
  UTM_VALUES_LAST_UPDATE_LOCAL_STORAGE_KEY,
} from '~/shared/constants';
import { UtmTags } from '~/composables/utm/utm.types';

export default (context: Context) => {
  if (process.client) {
    const query = context.query;

    const utmValues = Object.keys(query)
      .filter((key) => UTM_AVAILABLE_PARAMS.includes(key))
      .reduce((finalUtmObject, currentParamKey) => {
        return {
          ...finalUtmObject,
          [currentParamKey]: query[currentParamKey]?.toString() || '',
        };
      }, {} as UtmTags);

    if (isEmpty(query) || isEmpty(utmValues)) {
      return;
    }

    localStorage.setItem(
      UTM_VALUES_LOCAL_STORAGE_KEY,
      JSON.stringify(utmValues)
    );

    localStorage.setItem(
      UTM_VALUES_LAST_UPDATE_LOCAL_STORAGE_KEY,
      dayjs().toString()
    );
  }
};
