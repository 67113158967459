import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _791b8919 = () => interopDefault(import('../src/pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _33592ecc = () => interopDefault(import('../src/pages/accessibility/index.vue' /* webpackChunkName: "pages/accessibility/index" */))
const _04ee0f6c = () => interopDefault(import('../src/pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _348bb8b5 = () => interopDefault(import('../src/pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _5791a744 = () => interopDefault(import('../src/pages/courses/index.vue' /* webpackChunkName: "pages/courses/index" */))
const _56df9a27 = () => interopDefault(import('../src/pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _33486ee1 = () => interopDefault(import('../src/pages/for-organisations/index.vue' /* webpackChunkName: "pages/for-organisations/index" */))
const _fff79c16 = () => interopDefault(import('../src/pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _88443fcc = () => interopDefault(import('../src/pages/policies/index.vue' /* webpackChunkName: "pages/policies/index" */))
const _cde63ff2 = () => interopDefault(import('../src/pages/privacy-notice/index.vue' /* webpackChunkName: "pages/privacy-notice/index" */))
const _f7ae545e = () => interopDefault(import('../src/pages/sale/index.vue' /* webpackChunkName: "pages/sale/index" */))
const _344030f3 = () => interopDefault(import('../src/pages/studying-online/index.vue' /* webpackChunkName: "pages/studying-online/index" */))
const _13384806 = () => interopDefault(import('../src/pages/terms-of-purchase/index.vue' /* webpackChunkName: "pages/terms-of-purchase/index" */))
const _5e007bbe = () => interopDefault(import('../src/pages/terms-of-use/index.vue' /* webpackChunkName: "pages/terms-of-use/index" */))
const _00546cff = () => interopDefault(import('../src/pages/account/activation.vue' /* webpackChunkName: "pages/account/activation" */))
const _6c6703f4 = () => interopDefault(import('../src/pages/account/complete-registration.vue' /* webpackChunkName: "pages/account/complete-registration" */))
const _2cf7fb33 = () => interopDefault(import('../src/pages/account/create-password.vue' /* webpackChunkName: "pages/account/create-password" */))
const _f3341852 = () => interopDefault(import('../src/pages/account/forgotten-password.vue' /* webpackChunkName: "pages/account/forgotten-password" */))
const _0351a97c = () => interopDefault(import('../src/pages/account/reset-password.vue' /* webpackChunkName: "pages/account/reset-password" */))
const _8aea4c46 = () => interopDefault(import('../src/pages/embed/learners-and-parents-brochure-sign-up/index.vue' /* webpackChunkName: "pages/embed/learners-and-parents-brochure-sign-up/index" */))
const _0e5e34d6 = () => interopDefault(import('../src/pages/embed/organisations-brochure.vue' /* webpackChunkName: "pages/embed/organisations-brochure" */))
const _00e5a306 = () => interopDefault(import('../src/pages/login/consent.vue' /* webpackChunkName: "pages/login/consent" */))
const _d872b4de = () => interopDefault(import('../src/pages/login/proxy.vue' /* webpackChunkName: "pages/login/proxy" */))
const _076e615f = () => interopDefault(import('../src/pages/for-organisations/why-book/WhyChoose.vue' /* webpackChunkName: "pages/for-organisations/why-book/WhyChoose" */))
const _62ca05db = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0d2b5267 = () => interopDefault(import('../src/pages/blog/search/_searchString.vue' /* webpackChunkName: "pages/blog/search/_searchString" */))
const _104bb5dc = () => interopDefault(import('../src/pages/blog/tag/_blogTagSlug/index.vue' /* webpackChunkName: "pages/blog/tag/_blogTagSlug/index" */))
const _77b9dde7 = () => interopDefault(import('../src/pages/blog/_postSlug/index.vue' /* webpackChunkName: "pages/blog/_postSlug/index" */))
const _14e3ef68 = () => interopDefault(import('../src/pages/course-lead/_courseLeadSlug/index.vue' /* webpackChunkName: "pages/course-lead/_courseLeadSlug/index" */))
const _d7fe7c8c = () => interopDefault(import('../src/pages/courses/_courseSlug/index.vue' /* webpackChunkName: "pages/courses/_courseSlug/index" */))
const _45ff4764 = () => interopDefault(import('../src/pages/for-organisations/_b2bPageSlug.vue' /* webpackChunkName: "pages/for-organisations/_b2bPageSlug" */))
const _65e2d5d6 = () => interopDefault(import('../src/pages/courses/_courseSlug/enquiry-form.vue' /* webpackChunkName: "pages/courses/_courseSlug/enquiry-form" */))
const _3ac301a5 = () => interopDefault(import('../src/pages/courses/_courseSlug/book-a-place/_bookingSlug.vue' /* webpackChunkName: "pages/courses/_courseSlug/book-a-place/_bookingSlug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _791b8919,
    name: "about"
  }, {
    path: "/accessibility",
    component: _33592ecc,
    name: "accessibility"
  }, {
    path: "/blog",
    component: _04ee0f6c,
    name: "blog"
  }, {
    path: "/contact-us",
    component: _348bb8b5,
    name: "contact-us"
  }, {
    path: "/courses",
    component: _5791a744,
    name: "courses"
  }, {
    path: "/faqs",
    component: _56df9a27,
    name: "faqs"
  }, {
    path: "/for-organisations",
    component: _33486ee1,
    name: "for-organisations"
  }, {
    path: "/login",
    component: _fff79c16,
    name: "login"
  }, {
    path: "/policies",
    component: _88443fcc,
    name: "policies"
  }, {
    path: "/privacy-notice",
    component: _cde63ff2,
    name: "privacy-notice"
  }, {
    path: "/sale",
    component: _f7ae545e,
    name: "sale"
  }, {
    path: "/studying-online",
    component: _344030f3,
    name: "studying-online"
  }, {
    path: "/terms-of-purchase",
    component: _13384806,
    name: "terms-of-purchase"
  }, {
    path: "/terms-of-use",
    component: _5e007bbe,
    name: "terms-of-use"
  }, {
    path: "/account/activation",
    component: _00546cff,
    name: "account-activation"
  }, {
    path: "/account/complete-registration",
    component: _6c6703f4,
    name: "account-complete-registration"
  }, {
    path: "/account/create-password",
    component: _2cf7fb33,
    name: "account-create-password"
  }, {
    path: "/account/forgotten-password",
    component: _f3341852,
    name: "account-forgotten-password"
  }, {
    path: "/account/reset-password",
    component: _0351a97c,
    name: "account-reset-password"
  }, {
    path: "/embed/learners-and-parents-brochure-sign-up",
    component: _8aea4c46,
    name: "embed-learners-and-parents-brochure-sign-up"
  }, {
    path: "/embed/organisations-brochure",
    component: _0e5e34d6,
    name: "embed-organisations-brochure"
  }, {
    path: "/login/consent",
    component: _00e5a306,
    name: "login-consent"
  }, {
    path: "/login/proxy",
    component: _d872b4de,
    name: "login-proxy"
  }, {
    path: "/for-organisations/why-book/WhyChoose",
    component: _076e615f,
    name: "for-organisations-why-book-WhyChoose"
  }, {
    path: "/",
    component: _62ca05db,
    name: "index"
  }, {
    path: "/blog/search/:searchString?",
    component: _0d2b5267,
    name: "blog-search-searchString"
  }, {
    path: "/blog/tag/:blogTagSlug",
    component: _104bb5dc,
    name: "blog-tag-blogTagSlug"
  }, {
    path: "/blog/:postSlug",
    component: _77b9dde7,
    name: "blog-postSlug"
  }, {
    path: "/course-lead/:courseLeadSlug",
    component: _14e3ef68,
    name: "course-lead-courseLeadSlug"
  }, {
    path: "/courses/:courseSlug",
    component: _d7fe7c8c,
    name: "courses-courseSlug"
  }, {
    path: "/for-organisations/:b2bPageSlug?",
    component: _45ff4764,
    name: "for-organisations-b2bPageSlug"
  }, {
    path: "/courses/:courseSlug/enquiry-form",
    component: _65e2d5d6,
    name: "courses-courseSlug-enquiry-form"
  }, {
    path: "/courses/:courseSlug/book-a-place/:bookingSlug?",
    component: _3ac301a5,
    name: "courses-courseSlug-book-a-place-bookingSlug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
