export class FlexInstaller {
  private _flexScriptUrl: string;
  private _placeholderId: string;
  private _document: Document;
  private _placeholderElement: HTMLElement | null;

  constructor(
    flexScriptUrl: string,
    placeHolderId: string,
    document: Document
  ) {
    this._flexScriptUrl = flexScriptUrl;
    this._placeholderId = placeHolderId;
    this._document = document;
    this._placeholderElement = this._document.getElementById(
      this._placeholderId
    );
  }

  private setScriptAttributes(flexScript: HTMLScriptElement) {
    flexScript.src = this._flexScriptUrl;
    flexScript.type = 'text/javascript';
    flexScript.defer = true;
  }

  private appendOptanonWrapperFunctionToWindow() {
    // @ts-ignore
    window.OptanonWrapper = function () {};
  }

  private insertElementsIntoDOM(onetrustScript: HTMLScriptElement) {
    this._placeholderElement?.parentNode?.insertBefore(
      onetrustScript,
      this._placeholderElement
    );
  }

  private removePlaceholderElement() {
    this._placeholderElement?.parentNode?.removeChild(this._placeholderElement);
  }

  public installFlex() {
    const flexScript = document.createElement('script');
    this.setScriptAttributes(flexScript);
    this.appendOptanonWrapperFunctionToWindow();

    this.insertElementsIntoDOM(flexScript);
    this.removePlaceholderElement();
  }
}
