import { BookingState, StoreActionContext } from '@/shared/types/store';

export const state = (): BookingState => ({
  selectedCourseDateProductSku: '',
  courseCostCurrency: '',
});

export const mutations = {
  SET_SELECTED_COURSE_DATE_PRODUCT_SKU(
    state: BookingState,
    productSku: string
  ) {
    state.selectedCourseDateProductSku = productSku;
  },
  SET_COURSE_COST_CURRENCY(state: BookingState, courseCostCurrency: string) {
    state.courseCostCurrency = courseCostCurrency;
  },
};

export const actions = {
  setSelectedCourseDateProductSku(
    context: StoreActionContext,
    productSku: string
  ): void {
    context.commit('SET_SELECTED_COURSE_DATE_PRODUCT_SKU', productSku);
  },
  setCourseCostCurrency(
    context: StoreActionContext,
    courseCostCurrency: string
  ): void {
    context.commit('SET_COURSE_COST_CURRENCY', courseCostCurrency);
  },
};
