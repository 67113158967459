import { Context } from '@nuxt/types';
import { removeHttpProtocolFromUrl } from '@/shared/utils';

type PageInfo = {
  host: string;
  path: string;
  pageName: string | null;
};

type MarketingSubdomainRedirectorConfiguration = {
  marketingHost: string;
  nonMarketingHost: string;
  marketingNeutralPageNames: string[];
};

enum HttpProtocol {
  HTTP = 'http',
  HTTPS = 'https',
}

export class MarketingSubdomainRedirector {
  private readonly _context: Context;
  private readonly _hostWithCaoMarketing: string;
  private readonly _hostWithoutCaoMarketing: string;
  private readonly _protocol: HttpProtocol;
  private readonly _caoMarketingNeutralPageNames: string[];

  constructor(
    configuration: MarketingSubdomainRedirectorConfiguration,
    context: Context
  ) {
    const { marketingHost, nonMarketingHost, marketingNeutralPageNames } =
      configuration;
    this._context = context;
    this._hostWithCaoMarketing = removeHttpProtocolFromUrl(marketingHost);
    this._hostWithoutCaoMarketing = removeHttpProtocolFromUrl(nonMarketingHost);
    this._caoMarketingNeutralPageNames = marketingNeutralPageNames;
    this._protocol = this.isDev() ? HttpProtocol.HTTP : HttpProtocol.HTTPS; // this logic should be revisited once we know the final configuration
  }

  private isDev() {
    return process.env.NODE_ENV === 'development';
  }

  private getHost() {
    return process.browser
      ? window.location.host
      : this._context.req.headers.host;
  }

  handle() {
    const { route } = this._context;
    const host = this.getHost();
    const path = route.fullPath;
    const pageName = route.name!;

    switch (host) {
      case this._hostWithCaoMarketing:
        this.handlerForHostWithCaoMarketing(this._context, {
          host,
          path,
          pageName,
        });
        break;
      case this._hostWithoutCaoMarketing:
        this.handlerForHostWithoutCaoMarketing(this._context, {
          host,
          path,
          pageName,
        });
        break;
    }
  }

  private handlerForHostWithCaoMarketing(context: Context, pageInfo: PageInfo) {
    const { path, pageName } = pageInfo;

    const isErrorPage = context.route.name === null;
    const isCaoMarketingNeutralPage =
      pageName && this._caoMarketingNeutralPageNames.includes(pageName);
    if (!isErrorPage && isCaoMarketingNeutralPage) {
      const nonMarketingUrl = `${this._protocol}://${this._hostWithoutCaoMarketing}${path}`;
      return this._context.redirect(301, nonMarketingUrl);
    }
  }

  private handlerForHostWithoutCaoMarketing(
    context: Context,
    pageInfo: PageInfo
  ) {
    const { pageName } = pageInfo;

    const isErrorPage = context.route.name === null;
    const isCaoMarketingNeutralPage =
      pageName && this._caoMarketingNeutralPageNames.includes(pageName);
    if (!isErrorPage && !isCaoMarketingNeutralPage) {
      const pageNotFoundUrl = `${this._protocol}://${this._hostWithoutCaoMarketing}/page-not-found`;
      return this._context.redirect(301, pageNotFoundUrl);
    }
  }
}
