// @ts-nocheck
import {
  StoreActionContext,
  MaintenanceMessageState,
} from '@/shared/types/store';

import GetMaintenanceMessage from '@/shared/graphql/getMaintenanceMessage.gql';
import {
  GetMaintenanceMessageQuery,
  GetMaintenanceMessageQueryVariables,
} from '@/shared/graphql/schema.types';
export const state = (): MaintenanceMessageState => ({
  message: null,
});

export const mutations = {
  SET_MAINTENANCE_MESSAGE(
    state: MaintenanceMessageState,
    value: MaintenanceMessageState | null
  ) {
    state.message = value?.message;
    state.websiteBannerStyling = value?.websiteBannerStyling;
  },
};

export const actions = {
  async getMessage(context: StoreActionContext): Promise<void> {
    const response = await this.$gqlFetch<
      GetMaintenanceMessageQuery,
      GetMaintenanceMessageQueryVariables
    >(GetMaintenanceMessage, {});
    context.commit(
      'SET_MAINTENANCE_MESSAGE',
      response.data.maintenanceMessage || null
    );
  },
};
