// https://github.com/nuxt-community/axios-module/issues/547
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import { Context, Inject } from '@nuxt/types/app';

export type GqlFetchResponse<GQ> = {
  data: GQ;
  errors: object[] | undefined;
};

// inpired by https://v4.apollo.vuejs.org/guide-composable/error-handling.html#error-policies
export type GqlFetchOpts = {
  // 'none'  does not return any data if error is present
  // 'all' provides both data and the errors, allows partial display
  errorPolicy: 'all' | 'none';
};

export type GqlFetchDeps = {
  axios: NuxtAxiosInstance;
  sentry: any;
};

export type GqlFetch = <GQ, GV>(
  query: string,
  variables?: GV,
  opts?: GqlFetchOpts
) => Promise<GqlFetchResponse<GQ>>;

export default (context: Context, inject: Inject) => {
  const { $axios, $sentry } = context;
  const gqlFetch: GqlFetch = async (query, variables, opts) => {
    // we default to 'all' policy, as it still get reported to sentry
    // and there is some chance user still will see at least something
    const errorPolicy = opts?.errorPolicy || 'all';

    try {
      const response = await $axios.post('/graphql', {
        query,
        variables,
      });

      if (response.data?.errors?.length) {
        const gqlError = new Error('GQL Error');
        // @ts-ignore
        gqlError.gqlErrors = response.data.errors;

        if (errorPolicy === 'none') {
          throw gqlError;
        } else {
          $sentry.captureException(gqlError);
        }
      }

      return {
        data: response.data.data,
        errors: response.data?.errors,
      };
    } catch (e) {
      $sentry.captureException(e);
      throw e;
    }
  };

  // Inject $hello(msg) in Vue, context and store.
  inject('gqlFetch', gqlFetch);
};
