import { Context, Inject } from '@nuxt/types/app';
import * as Bowser from 'bowser';

export default function (_: Context, inject: Inject): void {
  const browser = Bowser.getParser(window.navigator.userAgent);

  inject('browser', {
    name: browser.getBrowserName(),
    version: browser.getBrowserVersion(),
    type: browser.getPlatformType(),
  });
}
