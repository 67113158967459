export class ShareaholicInstaller {
  private _shareaholicScriptUrl: string;
  private _shareaholicSiteId: string;
  private _placeholderId: string;
  private _document: Document;
  private _placeholderElement: HTMLElement | null;

  constructor(
    shareaholicScriptUrl: string,
    shareaholicSiteId: string,
    placeholderId: string,
    document: Document
  ) {
    this._shareaholicScriptUrl = shareaholicScriptUrl;
    this._shareaholicSiteId = shareaholicSiteId;
    this._placeholderId = placeholderId;
    this._document = document;
    this._placeholderElement = this._document.getElementById(
      this._placeholderId
    );
  }

  private setMetaAttributes(shareaholicMeta: HTMLMetaElement) {
    shareaholicMeta.name = 'shareaholic:site_id';
    shareaholicMeta.content = this._shareaholicSiteId;
  }

  private setScriptAttributes(shareaholicScript: HTMLScriptElement) {
    shareaholicScript.src = this._shareaholicScriptUrl;
    shareaholicScript.type = 'text/javascript';
    shareaholicScript.async = true;
    shareaholicScript.setAttribute('data-cfasync', 'false');
  }

  private insertElementsIntoDOM(element: HTMLElement) {
    this._placeholderElement?.parentNode?.insertBefore(
      element,
      this._placeholderElement
    );
  }

  private removePlaceholderElement() {
    this._placeholderElement?.parentNode?.removeChild(this._placeholderElement);
  }

  public installShareaholic() {
    const shareaholicMeta = document.createElement('meta');
    this.setMetaAttributes(shareaholicMeta);
    this.insertElementsIntoDOM(shareaholicMeta);

    const shareaholicScript = document.createElement('script');
    this.setScriptAttributes(shareaholicScript);
    this.insertElementsIntoDOM(shareaholicScript);

    this.removePlaceholderElement();
  }
}
