export const API = {
  captcha: {
    validateURL: '/api/v1/captcha/validateCaptcha',
  },
  gigya: {
    requestCompleteRegistrationEmailUrl:
      '/api/v1/gigya/expired-complete-registration-email',

    requestPasswordResetEmailUrl: '/api/v1/gigya/request-password-reset-email',
    requestPasswordReset: '/api/v1/gigya/reset-password',
    createPassword: '/api/v1/gigya/create-password',
    getAccountInfo: '/api/v1/gigya/get-account-info',
  },
  marketing: {
    marketingSignup: '/api/v1/marketing/marketing-signup',
    performB2bEnquiry: '/api/v1/marketing/perform-b2b-enquiry',
    b2bBrochureAccess: '/api/v1/marketing/b2b-brochure-access',
  },
  navigation: {
    b2bPages: '/api/v1/b2b-pages/b2b-pages',
  },
  ecommerce: {
    paypalApproval: '/api/v1/ecommerce/paypal-approval',
    postPaymentEnrolment: '/api/v1/ecommerce/post-payment-enrolment',
    validateActivationCode: '/api/v1/ecommerce/validate-activation-code',
    checkExistingUserEnroled: '/api/v1/ecommerce/check-existing-user-enroled',
    paypalCreateOrder: '/api/v1/ecommerce/paypal-create-order',
    paypalCaptureOrder: '/api/v1/ecommerce/paypal-capture-order',
  },
};

export const externalAPI = {
  calendly: {
    bookACall: '/d/d4m-hzr-x3m',
    b2bBookACall: '/d/g54-kt9-mvb',
  },
};
