import { gql } from '@/shared/utils';

export default gql`
  query GetMaintenanceMessage {
    maintenanceMessage {
      message
      websiteBannerStyling
    }
  }
`;
