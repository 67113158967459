import { Context, Inject } from '@nuxt/types/app';
import { isPreviewHost } from '~/shared/utils/preview/preview.utils';

export default function (ctx: Context, inject: Inject): void {
  const isInPreviewMode = (): Boolean => {
    const host = ctx.req?.headers?.host || window?.location.hostname || '';
    return isPreviewHost(host);
  };
  inject('isInPreviewMode', isInPreviewMode);
}
