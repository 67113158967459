const middleware = {}

middleware['caoMarketingNeutralSubdomainRedirection/caoMarketingNeutralSubdomainRedirection'] = require('../src/middleware/caoMarketingNeutralSubdomainRedirection/caoMarketingNeutralSubdomainRedirection.ts')
middleware['caoMarketingNeutralSubdomainRedirection/caoMarketingNeutralSubdomainRedirection'] = middleware['caoMarketingNeutralSubdomainRedirection/caoMarketingNeutralSubdomainRedirection'].default || middleware['caoMarketingNeutralSubdomainRedirection/caoMarketingNeutralSubdomainRedirection']

middleware['caoMarketingNeutralSubdomainRedirection/marketingNeutralPageNames'] = require('../src/middleware/caoMarketingNeutralSubdomainRedirection/marketingNeutralPageNames.ts')
middleware['caoMarketingNeutralSubdomainRedirection/marketingNeutralPageNames'] = middleware['caoMarketingNeutralSubdomainRedirection/marketingNeutralPageNames'].default || middleware['caoMarketingNeutralSubdomainRedirection/marketingNeutralPageNames']

middleware['caoMarketingNeutralSubdomainRedirection/marketingSubdomainRedirector'] = require('../src/middleware/caoMarketingNeutralSubdomainRedirection/marketingSubdomainRedirector.ts')
middleware['caoMarketingNeutralSubdomainRedirection/marketingSubdomainRedirector'] = middleware['caoMarketingNeutralSubdomainRedirection/marketingSubdomainRedirector'].default || middleware['caoMarketingNeutralSubdomainRedirection/marketingSubdomainRedirector']

middleware['mobile'] = require('../src/middleware/mobile.ts')
middleware['mobile'] = middleware['mobile'].default || middleware['mobile']

export default middleware
