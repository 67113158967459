var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._b({staticClass:"alert-box border-black border-l-8 relative py-30px px-20px flex",class:{
    'alert-error': _vm.isError,
    'alert-success': _vm.isSuccess,
    'alert-info': _vm.isInfo,
    'items-center': !_vm.isCardError,
    'alert-prompt': _vm.isPrompt,
  },attrs:{"autofocus":_vm.autofocus,"tabindex":"0","role":_vm.roleType,"aria-live":"assertive","aria-atomic":"true"}},'div',_vm.$attrs,false),[_c('span',{class:{
      'mr-20px block': !_vm.isInfo,
      'absolute top-4 right-4 block': _vm.isInfo,
    },attrs:{"data-testid":"alert-message-icon-wrapper"}},[(_vm.isError)?_c('Icon',{staticClass:"text-red",class:{
        'mt-2': _vm.isCardError,
      },attrs:{"data-testid":"alert-message-icon","name":"alert"}}):(_vm.isSuccess)?_c('Icon',{staticClass:"text-green",attrs:{"data-testid":"alert-message-icon","name":"circle-tick"}}):(_vm.isInfo)?_c('Icon',{staticClass:"text-info",attrs:{"data-testid":"alert-message-icon","name":"info"}}):(_vm.isPrompt)?_c('Icon',{staticClass:"text-link",attrs:{"data-testid":"alert-message-icon","name":"alert"}}):_vm._e()],1),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }