export class GoogleTagManagerInstaller {
  private _dataLayerId: string;
  private _googleTagManagerId: string;
  private _placeholderElementId: string = 'gtm-placeholder';
  private _placeholderElement: HTMLElement | null;
  private _window: Window;
  private _document: Document;
  private _isLastTag?: boolean = false;

  constructor(
    dataLayerId: string,
    googleTagManagerId: string,
    window: Window,
    document: Document,
    isLastTag?: boolean
  ) {
    this._dataLayerId = dataLayerId;
    this._googleTagManagerId = googleTagManagerId;
    this._placeholderElement = document.getElementById(
      this._placeholderElementId
    );
    this._window = window;
    this._document = document;
    this._isLastTag = isLastTag;
  }

  private addElementsToWindow(): void {
    // @ts-ignore
    this._window[this._dataLayerId] = this._window[this._dataLayerId] || [];
    // @ts-ignore
    this._window[this._dataLayerId].push({
      // @ts-ignore
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
  }

  private prepareDataLayerQueryParameter(): string {
    return this._dataLayerId !== 'dataLayer' ? `&l=${this._dataLayerId}` : '';
  }

  private setGTMScriptAttributes(
    googleTagManagerScript: HTMLScriptElement,
    dataLayerQueryParameter: string
  ): void {
    googleTagManagerScript.async = true;
    googleTagManagerScript.src = `https://www.googletagmanager.com/gtm.js?id=${this._googleTagManagerId}${dataLayerQueryParameter}`;
  }

  private insertElementsIntoDOM(
    googleTagManagerScript: HTMLScriptElement
  ): void {
    const placeholder = this._placeholderElement;
    placeholder?.parentNode?.insertBefore(googleTagManagerScript, placeholder);
  }

  public removePlaceholderElement(): void {
    this._placeholderElement?.parentNode?.removeChild(this._placeholderElement);
  }

  public installGoogleTagManager() {
    this.addElementsToWindow();
    const dataLayerParameter = this.prepareDataLayerQueryParameter();

    if (this._placeholderElement) {
      const googleTagManagerScript = this._document.createElement('script');
      this.setGTMScriptAttributes(googleTagManagerScript, dataLayerParameter);
      this.insertElementsIntoDOM(googleTagManagerScript);
      if (this._isLastTag) this.removePlaceholderElement();
    }
  }
}

export const includeGoogleTagManager = (
  googleTagManagerId: string | undefined,
  isLastTag: boolean = false
) => {
  const dataLayerIdentifier = 'dataLayer';

  if (!process.browser || !window) {
    return;
  }

  const googleTagManagerInstaller = new GoogleTagManagerInstaller(
    dataLayerIdentifier,
    googleTagManagerId ?? '',
    window,
    document,
    isLastTag
  );

  if (!googleTagManagerId) {
    googleTagManagerInstaller.removePlaceholderElement();
    return;
  }

  googleTagManagerInstaller.installGoogleTagManager();
};
