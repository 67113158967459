
import { defineComponent } from '@nuxtjs/composition-api';
import MobileNav from './components/MobileNav.vue';
import DesktopNav from './components/DesktopNav.vue';
import LogoSet from '@/components/logo/LogoSet.vue';
import SectionContentContainer from '@/components/section-content-container/SectionContentContainer.vue';
import { useCaoBranding } from '~/composables/branding/useCaoBranding';

export default defineComponent({
  components: {
    SectionContentContainer,
    LogoSet,
    MobileNav,
    DesktopNav,
  },
  setup: () => {
    const { hasCaoBranding } = useCaoBranding();

    return { hasCaoBranding };
  },
});
