import { computed, useRoute } from '@nuxtjs/composition-api';
import { includeGoogleTagManager } from '~/shared/utils';

const multipleGtmRoutes = ['/courses/:courseSlug/book-a-place/:bookingSlug?'];

export const useGtm = () => {
  const route = useRoute();

  const hasMultipleGtm = computed(
    () =>
      !!route.value.matched.find((matchedRoute) =>
        multipleGtmRoutes.includes(matchedRoute.path)
      ) && !!route.value.matched.length
  );

  const installGtm = (gtmId?: string, overrideIsLastTag?: boolean) => {
    includeGoogleTagManager(gtmId, overrideIsLastTag ?? !hasMultipleGtm.value);
  };

  return {
    hasMultipleGtm,
    installGtm,
  };
};
