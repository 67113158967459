
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    width: {
      type: Number,
      default: 34,
    },
    color: {
      type: String,
      default: '#000000',
    },
  },
  setup(props) {
    return {
      height: (props.width * 26) / 34,
    };
  },
});
