
import {
  PropType,
  defineComponent,
  ref,
  nextTick,
  useRoute,
  computed,
  watch,
} from '@nuxtjs/composition-api';
import { onClickOutside } from '@vueuse/core';
import Icon from '@/components/icon/Icon.vue';
import { NavigationSubItem } from '@/shared/types/store';
import { FeatureFlagName } from '@/shared/types/feature-flags/feature-flags';
import { KeyboardEventKey } from '@/shared/types/common/common.types';
import { ZENDESK_HELP_CENTRE } from '@/shared/constants';

export default defineComponent({
  components: {
    Icon,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      default: '#',
    },
    useRedirect: {
      type: Boolean,
      default: false,
    },
    linkClass: {
      type: String,
      default: null,
    },
    featureName: {
      type: String,
      default: '',
    },
    isDropdown: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<NavigationSubItem[] | [] | undefined>,
      default: () => [],
    },
  },
  setup: (props) => {
    const isDropdownActive = ref<boolean>(false);
    const target = ref<HTMLDivElement | null>(null);
    const router = useRoute();
    const dropdownAnnouncement = ref<string>('');

    const isAnyDropdownOptionActive = computed(() => {
      return !!props.options?.find((opt) => opt.path === router.value.path);
    });

    const toggleDropdown = async (isActive?: boolean) => {
      const newDropDownState = isActive ?? !isDropdownActive.value;
      isDropdownActive.value = newDropDownState;

      if (!newDropDownState) return;
      await nextTick();
      const dropdownElement = document.querySelector(
        '#nav-dropdown a'
      ) as HTMLElement;
      dropdownElement?.focus();
    };

    if (props.isDropdown) {
      onClickOutside(
        target,
        (event) => isDropdownActive.value && toggleDropdown(false)
      );
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      const dropdownToggle = document.querySelector(
        '#nav-dropdown-toggle'
      ) as HTMLElement;
      const nextElement =
        document.activeElement?.parentElement?.nextElementSibling?.querySelector(
          'a'
        ) as HTMLElement;
      const prevElement =
        document.activeElement?.parentElement?.previousElementSibling?.querySelector(
          'a'
        ) as HTMLElement;
      switch (event.key) {
        case KeyboardEventKey.ESC:
          toggleDropdown(false);
          dropdownToggle?.focus();
          break;
        case KeyboardEventKey.ARROW_UP:
        case KeyboardEventKey.ARROW_LEFT:
          event.preventDefault();
          event.stopPropagation();
          prevElement?.focus();
          break;
        case KeyboardEventKey.ARROW_DOWN:
        case KeyboardEventKey.ARROW_RIGHT:
          event.stopPropagation();
          event.preventDefault();
          nextElement?.focus();
          break;
      }
    };

    const handleLinkBlur = (event: FocusEvent) => {
      const dropdownElement = document.querySelector('#nav-dropdown');
      if (dropdownElement?.contains(event.relatedTarget as Node)) return;
      toggleDropdown(false);
    };

    const redirectUrl = computed(() => {
      return props.useRedirect ? ZENDESK_HELP_CENTRE : props.path;
    });

    watch(isDropdownActive, (newVal) => {
      dropdownAnnouncement.value = `${props.text} dropdown ${
        newVal ? 'expanded' : 'collapsed'
      }`;
    });

    return {
      handleKeyDown,
      handleLinkBlur,
      isDropdownActive,
      toggleDropdown,
      navigationDropdownFeatureFlag: FeatureFlagName.USE_B2B_PAGES,
      target,
      isAnyDropdownOptionActive,
      dropdownAnnouncement,
      redirectUrl,
    };
  },
});
