
import {
  defineComponent,
  ref,
  watch,
  computed,
  PropType,
} from '@nuxtjs/composition-api';
import Icon from '@/components/icon/Icon.vue';

type CallbackOnToggle = (id: string, isCollapsedValue: boolean) => void;
type CallbackOnEnabled = () => void;

export default defineComponent({
  components: { Icon },
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: '',
    },
    onToggle: {
      type: Function as PropType<CallbackOnToggle>,
      default: () => {},
    },
    onEnabled: {
      type: Function as PropType<CallbackOnEnabled>,
      default: () => {},
    },
  },
  setup: (props) => {
    const contentId = computed(
      () => props.id || props.title.toLowerCase().replace(' ', '')
    );
    const isExpanded = ref(props.expanded);
    const toggleExpanded = (isCollapsedValue: boolean) => {
      props.onToggle(contentId.value, isCollapsedValue);
      isExpanded.value = isCollapsedValue;
    };

    watch(
      () => props.expanded,
      () => {
        toggleExpanded(props.expanded);
        if (props.expanded) {
          props.onEnabled();
        }
      }
    );

    return {
      isExpanded,
      contentId,
      toggleExpanded,
    };
  },
});
