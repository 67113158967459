
import { defineComponent } from '@nuxtjs/composition-api';
import SocialMediaItem from './components/SocialMediaItem.vue';

export default defineComponent({
  components: {
    SocialMediaItem,
  },
  props: {
    includeFacebookIcon: {
      type: Boolean,
      default: true,
    },
    includeTwitterIcon: {
      type: Boolean,
      default: true,
    },
    includeLinkedInIcon: {
      type: Boolean,
      default: true,
    },
  },
});
