export type ActivationFormData = {
  firstName: string;
  lastName: string;
  country: string;
  emailAddress: string;
  password: string;
  confirmPassword: string;
  marketingConsentChecked: boolean;
};

export type PasswordFormData = {
  newPassword: string;
  confirmedPassword: string;
  marketingConsentChecked?: boolean;
};

export type PasswordFormProps = {
  formData: PasswordFormData;
  isSubmitPending?: boolean;
  isSubmitError?: boolean;
  isSubmitSuccess?: boolean;
  isTechnicalError?: boolean;
  isExpiredError?: boolean;
  passwordType?: string;
};

export enum PostPaymentEnrolmentError {
  ACTIVATION_CODE = 'ACTIVATION_CODE_DOES_NOT_EXIST_ERROR',
  ALL_PLACES_USED = 'ALL_PLACES_HAVE_BEEN_USED_ERROR',
  ALREADY_ENROLED = 'USER_ALREADY_ENROLED_ERROR',
  ALREADY_REGISTERED = 'USER_ALREADY_REGISTERED_ERROR',
}
