
import { getCurrentInstance } from 'vue';
import { defineComponent, computed } from '@nuxtjs/composition-api';
import DesktopNavItem from './DesktopNavItem.vue';
import BaseButton from '@/components/buttons/base-button/BaseButton.vue';
import { NavigationItem } from '@/shared/types/store';

export default defineComponent({
  components: {
    BaseButton,
    DesktopNavItem,
  },
  setup: () => {
    const instance = getCurrentInstance();
    const { $store, $isFeatureEnabled } = instance!.proxy;
    const navItems = computed<NavigationItem[]>(() =>
      $store.state.navigation.main.filter(
        (item: NavigationItem) => item.isDisplayedInMainNav
      )
    );

    const isRedirectEnabled = (useRedirect?: string) =>
      computed(() => (useRedirect ? $isFeatureEnabled(useRedirect) : false))
        .value;

    return { navItems, isRedirectEnabled };
  },
});
