import { InfoPagesNavigationState } from '@/shared/types/store';

export const state = (): InfoPagesNavigationState => [
  {
    _id: 1,
    label: 'Terms of use',
    path: '/terms-of-use',
  },
  {
    _id: 2,
    label: 'Terms of purchase',
    path: '/terms-of-purchase',
  },
  {
    _id: 3,
    label: 'Privacy notice',
    path: '/privacy-notice',
  },
  {
    _id: 4,
    label: 'Accessibility',
    path: '/accessibility',
  },
];
