
import { defineComponent } from '@nuxtjs/composition-api';
import { CustomerServiceContactInfo } from '@/shared/types';
import AlertMessageCard from '@/components/cards/alert-message-card/AlertMessageCard.vue';

export default defineComponent({
  components: {
    AlertMessageCard,
  },
  setup: () => {
    const email = CustomerServiceContactInfo.EMAIL_ADDRESS;
    const ukTel = CustomerServiceContactInfo.UK_TEL_NUMBER;
    const usTel = CustomerServiceContactInfo.US_TEL_NUMBER;

    return {
      email,
      ukTel,
      usTel,
    };
  },
});
