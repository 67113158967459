// @ts-nocheck
import { StoreActionContext } from '@/shared/types/store';

export const mutations = {
  SAVE_FEATURE_FLAG_STORAGE_ITEM(
    state: {},
    payload: { name: string; data: any }
  ) {
    state[payload.name] = payload.data;
  },
};

export const actions = {
  setItem(
    context: StoreActionContext,
    payload: { name: string; data: any }
  ): void {
    context.commit('SAVE_FEATURE_FLAG_STORAGE_ITEM', payload);
  },
};
