// @ts-nocheck
import {
  StoreActionContext,
  ServiceInfoState,
  ServiceInfoResponse,
} from '@/shared/types/store';

export const state = (): ServiceInfoState => ({
  version: '',
});

export const mutations = {
  SET_SERVICE_INFO(state: ServiceInfoState, value: string) {
    state.version = value;
  },
};

export const actions = {
  getServiceInfo(context: StoreActionContext): Promise<void> {
    return this.$axios
      .get<ServiceInfoResponse>('/build')
      .then((res) => {
        return context.commit('SET_SERVICE_INFO', res.data.version);
      })
      .catch(() => context.commit('SET_SERVICE_INFO', 'error'));
  },
};
