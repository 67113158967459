export class GigyaInstaller {
  private _gigyaScriptUrl: string;
  private _placeholderId: string;
  private _document: Document;
  private _placeholderElement: HTMLElement | null;
  private _innerHTML: string | undefined;
  private _onLoad: Function | undefined;

  constructor(
    gigyaScriptUrl: string,
    placeHolderId: string,
    document: Document,
    innerHTML?: string,
    onScriptLoaded?: Function
  ) {
    this._gigyaScriptUrl = gigyaScriptUrl;
    this._placeholderId = placeHolderId;
    this._document = document;
    this._innerHTML = innerHTML;
    this._placeholderElement = this._document.getElementById(
      this._placeholderId
    );
    this._onLoad = onScriptLoaded;
  }

  private setScriptAttributes(gigyaScript: HTMLScriptElement) {
    gigyaScript.src = this._gigyaScriptUrl;
    gigyaScript.lang = 'javascript';
    gigyaScript.type = 'text/javascript';
    if (this._innerHTML) {
      gigyaScript.innerHTML = this._innerHTML;
    }

    if (this._onLoad !== undefined) {
      gigyaScript.onload = () => {
        this._onLoad && this._onLoad();
      };
    }
  }

  private appendOptanonWrapperFunctionToWindow() {
    // @ts-ignore
    window.OptanonWrapper = function () {};
  }

  private insertElementsIntoDOM(gigyaScript: HTMLScriptElement) {
    this._placeholderElement?.parentNode?.insertBefore(
      gigyaScript,
      this._placeholderElement
    );
  }

  private removePlaceholderElement() {
    this._placeholderElement?.parentNode?.removeChild(this._placeholderElement);
  }

  public install() {
    const gigyaScript = document.createElement('script');
    this.setScriptAttributes(gigyaScript);
    this.appendOptanonWrapperFunctionToWindow();

    this.insertElementsIntoDOM(gigyaScript);
    this.removePlaceholderElement();
  }
}

export const includeGigyaOidc = (
  gigyaOidcScriptUrl: string,
  placeholderId: string,
  siteUrl: string
) => {
  if (!gigyaOidcScriptUrl || !process.browser || !window || !document) {
    return;
  }

  const innerHtml = `{
    loginURL: "${siteUrl}/login",
    consentURL: "${siteUrl}/login/consent",
    errorURL: "${siteUrl}/login/proxy/error"
  }`;

  const gigyaOidcInstaller = new GigyaInstaller(
    gigyaOidcScriptUrl,
    placeholderId,
    document,
    innerHtml
  );

  gigyaOidcInstaller.install();
};

export const includeGigya = (
  gigyaScriptUrl: string,
  placeholderId: string,
  onLoaded: Function
) => {
  if (!gigyaScriptUrl || !process.browser || !window || !document) {
    return;
  }

  const gigyaInstaller = new GigyaInstaller(
    gigyaScriptUrl,
    placeholderId,
    document,
    undefined,
    onLoaded
  );

  gigyaInstaller.install();
};
