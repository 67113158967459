import {
  documentToHtmlString,
  Options,
} from '@contentful/rich-text-html-renderer';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import {
  Document,
  INLINES,
  NodeData,
  BLOCKS,
} from '@contentful/rich-text-types';
import { ContentMark } from './richText.types';

export const isLocalURI = (uri: string): boolean => {
  // in contentful only prod url might appear,
  // therefore make sense to check against it on all envs
  const prodURI = 'https://advanceonline.cam.ac.uk';
  return (
    uri.startsWith(prodURI) ||
    uri.startsWith('/') ||
    uri.startsWith('mailto') ||
    uri.startsWith('tel')
  );
};

const hasNodeTypeGivenMark = (marks: ContentMark[], type: string): boolean => {
  return marks.some((mark) => mark.type === type);
};

const renderMarks = (marks: ContentMark[], content: string) => {
  let taggedContent = content;

  if (hasNodeTypeGivenMark(marks, 'bold')) {
    taggedContent = `<b>${taggedContent}</b>`;
  }

  if (hasNodeTypeGivenMark(marks, 'italic')) {
    taggedContent = `<i>${taggedContent}</i>`;
  }

  if (hasNodeTypeGivenMark(marks, 'underline')) {
    taggedContent = `<u>${taggedContent}</u>`;
  }

  if (hasNodeTypeGivenMark(marks, 'subscript')) {
    taggedContent = `<sub>${taggedContent}</sub>`;
  }

  if (hasNodeTypeGivenMark(marks, 'superscript')) {
    taggedContent = `<sup>${taggedContent}</sup>`;
  }

  return taggedContent;
};

const renderHyperlink = (node: NodeData): string => {
  const { uri } = node.data;
  const { value, marks } = node.content[0];
  const target = isLocalURI(uri) ? '_self' : '_blank';
  const readerLinkMessage =
    target === '_blank'
      ? '<span class="sr-only">(Opens in a new window)</span>'
      : '';
  const link = `<a href="${uri}" target="${target}">${value}${readerLinkMessage}</a>`;

  return renderMarks(marks, link);
};

const renderRowMarks = (rowContent: NodeData): string => {
  if (rowContent.nodeType === 'hyperlink') {
    return renderHyperlink(rowContent);
  }
  if (rowContent.marks) {
    return renderMarks(rowContent.marks, rowContent.value);
  }
  return `${rowContent}`;
};

const renderRows = (node: NodeData): string => {
  const flattenedTable = node.content.map((item: NodeData) =>
    item.content.map((nestedItem: NodeData) =>
      nestedItem.content.length > 1
        ? nestedItem.content.reduce(
            (prev: NodeData, next: NodeData) =>
              renderRowMarks(prev) + renderRowMarks(next)
          )
        : renderRowMarks(nestedItem.content[0])
    )
  );
  const divElements = flattenedTable
    .map((innerArray: string[]) =>
      innerArray
        .map((value: string) => `<div class="mb-10px">${value}</div>`)
        .join('')
    )
    .map(
      (innerDivs: string) => `<div class="table-cell p-10px">${innerDivs}</div>`
    )
    .join('');

  const rowContent =
    node.nodeType === BLOCKS.TABLE_ROW
      ? divElements
      : node.content[0].content[0].value;

  return `<div class="table-row even:bg-page-grey">${rowContent}</div>`;
};

const renderConfig: Partial<Options> = {
  renderNode: {
    [BLOCKS.TABLE_ROW]: (node) => renderRows(node),
    [INLINES.HYPERLINK]: (node: NodeData): string => renderHyperlink(node),
  },
};

export const renderRichTextDocumentToHtml = (
  richTextDocument: Document
): string => {
  if (!richTextDocument) {
    return '';
  }

  return documentToHtmlString(richTextDocument, renderConfig)
    .replace(/\n/g, '<br/>')
    .replace(/\u00A0/g, ' ');
};

export const renderRichTextDocumentToPlainText = (
  richTextDocument: Document
): string => {
  if (!richTextDocument) {
    return '';
  }

  return documentToPlainTextString(richTextDocument, '<br/>');
};

export const isRichTextDocumentEmpty = (
  richTextDocument: Document
): boolean => {
  return !renderRichTextDocumentToHtml(richTextDocument)
    .replace(/(<([^>]+)>)/gi, '')
    .trim();
};
