
import { getCurrentInstance } from 'vue';
import { defineComponent, onMounted } from '@nuxtjs/composition-api';
import MaintenanceMessage from '@/components/banner/MaintenanceMessage.vue';

export default defineComponent({
  components: {
    MaintenanceMessage,
  },
  setup: () => {
    const instance = getCurrentInstance();
    const { $route, $sentry } = instance!.proxy;

    const customGtmDataLayerHandler = (): void => {
      if (!window.dataLayer) {
        const error = new Error('ERROR_GTM_DATA_LAYER_NOT_EXISTS');
        $sentry.captureException(error);
        return;
      }

      window.dataLayer.push({
        event: 'CustomPageView',
        pagePath: $route.path,
      });
    };

    onMounted(() => {
      customGtmDataLayerHandler();
    });
  },
});
