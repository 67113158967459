import dayjs from 'dayjs';
import { extend } from 'vee-validate';
import {
  required,
  email,
  oneOf,
  max,
  is,
  confirmed,
  min_value as minValue,
  integer,
} from 'vee-validate/dist/rules';
import { SoftEmbargoedCountries } from '@/shared/types/billing/billing.types';

interface ParamI {
  [index: string]: any;
}

extend('oneOfPaymentOption', {
  ...oneOf,
  message:
    'Bank transfers are not currently available via the website. Our customer support team can assist with your enquiry. Please see our <a href="/contact-us" target="_blank">Contact Us <span class="sr-only">(Opens in a new window)</span></a> page for more information.',
});

extend('required', {
  ...required,
  message: 'Please enter your {_field_}, this field is required.',
});

extend('requiredCheckbox', {
  ...required,
  validate: (value) => !!value,
  message: 'Please confirm {_field_}, this confirmation is required.',
});

extend('requiredSelect', {
  ...required,
  message: 'Please select your {_field_}, this is required.',
});

extend('validEmail', {
  ...email,
  message: 'Please enter valid email address.',
});

extend('participantNumberOptions', (value, optionsArr) => {
  const isNumber = integer.validate(value);

  if (isNumber && !minValue.validate(value, { min: 1 })) {
    return `The {_field_} cannot be less than 1.`;
  }

  if (!optionsArr.includes(value)) {
    return `Please enter a valid number of participants.`;
  }

  return true;
});

extend('notEmbargoed', (value) => {
  return value.isEmbargoed
    ? 'Ecommerce services are not available in your country.'
    : true;
});

extend('availableOnAdditionalCountryList', (value, countries) => {
  if (!value.label && !value.code) {
    return 'Please enter your country, this field is required.';
  }

  if (value.code && value.label) {
    return true;
  }
  const additionalValidCountry = countries.find(
    (country: SoftEmbargoedCountries) =>
      country.label.toLowerCase() === value.label.toLowerCase()
  );
  return !additionalValidCountry ? 'Country not found.' : true;
});

extend('uniqueStringValue', {
  params: ['allValues'],
  validate(value, { allValues = [] }: ParamI) {
    const values = typeof allValues === 'string' ? [allValues] : [...allValues];
    return values.filter((valueInRecord) => value === valueInRecord).length < 2;
  },
  message: 'This {_field_} value is duplicated',
});

extend('max', {
  ...max,
  message: `The {_field_} should not be longer than {length} characters.`,
});

extend('maxItems', (value, args) => {
  const maxParam = Array.isArray(args) ? parseInt(args[0]) : 0;

  return value.length > maxParam
    ? `The {_field_} should not have more than ${maxParam} entries.`
    : true;
});

extend('maxCharCountry', (value, args) => {
  const maxParam = Array.isArray(args) ? parseInt(args[0]) : 0;

  return value.label.length > maxParam
    ? `The {_field_} should not be longer than ${maxParam} characters.`
    : true;
});

extend('number', (value) => {
  return !/^\d+$/.test(value) ? `The {_field_} should be a number.` : true;
});

extend('maxNumber', (value, args) => {
  return Number(value) > Number(args)
    ? `The {_field_} cannot be more than ${args}.`
    : true;
});

extend('minNumber', (value, args) => {
  return Number(value) < Number(args)
    ? `The {_field_} cannot be less than ${args}.`
    : true;
});

extend('matchPassword', {
  ...is,
  message: 'Passwords do not match.',
});

extend('strongPassword', (value) => {
  // Regex for validation password base on all rules which exists in CDC
  // here is reference of regex https://regex101.com/r/YuvfqX/1
  const passwordValidationPattern =
    /^(?=.*?[a-zA-Z])((?=.*?[A-Z])|(?=.*?[0-9])|(?=.*?[`~!@#$%^&*()_+])).{8,}$/;
  const isValidPassword = passwordValidationPattern.test(value);
  return (
    isValidPassword ||
    'Passwords need to be eight (8) or more characters, and include one or more letters and numbers.'
  );
});

extend('phoneNumber', (value) => {
  // https://regex101.com/r/oiumlR/1
  const phoneValidationPattern = /^[0-9()+.-]{9,16}$/;
  const isValidPhoneNumber = phoneValidationPattern.test(
    value.replace(/\s/g, '')
  );
  return (
    isValidPhoneNumber ||
    'Phone number needs to be maximum sixteen (16) characters long and contain only digits, +, -, () or .'
  );
});

extend('expirationDateMonth', (value: string) => {
  const monthStringAsNumber = Number(value.slice(0, 2));
  const isValueValid = monthStringAsNumber >= 1 && monthStringAsNumber <= 12;

  return (
    isValueValid || `Month should be a two-digit number between 01 and 12.`
  );
});

extend('expirationDateInFuture', (value: string) => {
  const dateElements = value.split('/');
  const currentDate = dayjs(Date.now()).toDate();
  // months in dayjs are 0 indexed, so January is 0
  const enteredDate = dayjs()
    .set('month', Number(dateElements[0]) - 1)
    .set('year', Number(dateElements[1]));
  return (
    enteredDate.isAfter(currentDate, 'month') ||
    enteredDate.isSame(currentDate, 'month') ||
    `Expiration date can't be in the past.`
  );
});

extend('expirationDateFormat', (value) => {
  const expirationDateValidationPattern = /^(0?[1-9]|1[0-2])\/[0-9]{4}$/;
  const isValidExpirationDate = expirationDateValidationPattern.test(
    value.replace(/\s/g, '')
  );

  return (
    isValidExpirationDate || 'Expiration date should follow MM/YYYY format.'
  );
});

extend('confirmed', confirmed);
