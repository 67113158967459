
import { getCurrentInstance } from 'vue';
import { defineComponent, reactive, onMounted } from '@nuxtjs/composition-api';
import {
  BrowserInfo,
  UnsupportedBrowserStatus,
} from './unsupported-browser.types';
import {
  setUnsupportedBrowserStatus,
  getUnsupportedBrowserStatus,
  isUnsupportedBrowser,
} from './unsupported-browser.utils';
import Icon from '@/components/icon/Icon.vue';
import SectionContentContainer from '@/components/section-content-container/SectionContentContainer.vue';

export default defineComponent({
  components: {
    SectionContentContainer,
    Icon,
  },
  setup: (_, ctx) => {
    const instance = getCurrentInstance();
    const state = reactive({
      shouldDisplayUnsupportedBrowserMessage: false,
    });

    const acceptUnsupportedBrowserStatusHandler = () => {
      state.shouldDisplayUnsupportedBrowserMessage = false;
      setUnsupportedBrowserStatus(UnsupportedBrowserStatus.Accepted);
    };

    onMounted(() => {
      const unsupportedBrowserStatus = getUnsupportedBrowserStatus();
      // @ts-ignore
      const browser = instance?.proxy.$browser as BrowserInfo;

      if (!unsupportedBrowserStatus && isUnsupportedBrowser(browser)) {
        state.shouldDisplayUnsupportedBrowserMessage = true;
      }
    });

    return {
      acceptUnsupportedBrowserStatusHandler,
      state,
    };
  },
});
