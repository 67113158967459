export const removeHttpProtocolFromUrl = (url: string): string => {
  return url.replace(/http(?:s)?:\/\//, '');
};

export const moveToHref = (href: string): void => {
  if (document) {
    const temporaryLink = document.createElement('a');
    temporaryLink.href = href;
    document.body.appendChild(temporaryLink);
    temporaryLink.click();

    setTimeout(() => document.body.removeChild(temporaryLink), 100);
  }
};

export const downloadFileFromUrl = (url: string): void => {
  if (document) {
    const temporaryLink = document.createElement('a');
    temporaryLink.href = url;
    temporaryLink.target = '_blank';
    document.body.appendChild(temporaryLink);
    temporaryLink.click();
    document.body.removeChild(temporaryLink);
  }
};

export const createNavigationPathFromCMSLinktarget = (uri: string): string => {
  return encodeURIComponent(uri.trim().replace(' ', '-'));
};
