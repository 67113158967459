import { CourseCategory } from '~/shared/types/course/course.types';

type ThemeSet = {
  bgImage: string;
  largeBgImage: string;
  borderColor: string;
  textColor: string;
};

export const getBackgroundImage = (url: string, isLarge?: boolean): string => {
  return isLarge ? `${url}?w=1920` : `${url}?w=600`;
};

export const getThemeSetForCategory = (category: CourseCategory): ThemeSet => {
  return {
    bgImage: getBackgroundImage(category.backgroundImage),
    largeBgImage: getBackgroundImage(category.backgroundImage, true),
    borderColor: category.themeColorHex,
    textColor: category.themeColorHex,
  };
};
