export const marketingNeutralPageNames = [
  'courses-courseSlug-book-a-place-bookingSlug',
  'courses-courseSlug-enquiry-form',
  'login',
  'login-consent',
  'login-proxy',
  'account-activation',
  'account-create-password',
  'account-forgotten-password',
  'account-reset-password',
  'account-complete-registration',
];
