
import { defineComponent } from '@nuxtjs/composition-api';
import Icon from '@/components/icon/Icon.vue';
import BaseButton from '@/components/buttons/base-button/BaseButton.vue';

export default defineComponent({
  components: { Icon, BaseButton },
  props: {
    actionName: {
      type: String,
      default: '',
    },
    actionTitle: {
      type: String,
      default: '',
    },
    actionDescription: {
      type: String,
      default: '',
    },
    buttonType: {
      type: String,
      default: 'primary',
    },
    buttonTo: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonTarget: {
      type: String,
      default: '',
    },
  },
  setup: (props) => {
    const opensInNewWindow = props.buttonTarget === '_blank';

    return {
      opensInNewWindow,
    };
  },
});
