import { SurroundingComments } from '../thirdPartyInstallation.types';

export class OnetrustInstallator {
  private _onetrustScriptUrl: string;
  private _dataDocumentLanguageAttribute: string;
  private _dataDomainScriptAttribute: string;
  private _placeholderId: string;
  private _window: Window;
  private _document: Document;
  private _placeholderElement: HTMLElement | null;

  constructor(
    onetrustScriptUrl: string,
    dataDocumentLanguageAttribute: string,
    dataDomainScriptAttribute: string,
    placeHolderId: string,
    window: Window,
    document: Document
  ) {
    this._onetrustScriptUrl = onetrustScriptUrl;
    this._dataDocumentLanguageAttribute = dataDocumentLanguageAttribute;
    this._dataDomainScriptAttribute = dataDomainScriptAttribute;
    this._placeholderId = placeHolderId;
    this._window = window;
    this._document = document;
    this._placeholderElement = this._document.getElementById(
      this._placeholderId
    );
  }

  private setScriptAttributes(onetrustScript: HTMLScriptElement) {
    onetrustScript.src = this._onetrustScriptUrl;
    onetrustScript.type = 'text/javascript';
    onetrustScript.defer = true;
    onetrustScript.setAttribute(
      'data-document-language',
      this._dataDocumentLanguageAttribute
    );
    onetrustScript.setAttribute(
      'data-domain-script',
      this._dataDomainScriptAttribute
    );
  }

  private appendOptanonWrapperFunctionToWindow() {
    // @ts-ignore
    window.OptanonWrapper = function () {};
  }

  private createCommentsElements(): SurroundingComments {
    const onetrustStartComment = document.createComment(
      'OneTrust Cookies Consent Notice start for advanceonline.cam.ac.uk'
    );
    const onetrustEndComment = document.createComment(
      'OneTrust Cookies Consent Notice end for advanceonline.cam.ac.uk'
    );

    return {
      startComment: onetrustStartComment,
      endComment: onetrustEndComment,
    };
  }

  private insertElementsIntoDOM(
    onetrustScript: HTMLScriptElement,
    comments: SurroundingComments
  ) {
    const { startComment, endComment } = comments;
    this._placeholderElement?.parentNode?.insertBefore(
      startComment,
      this._placeholderElement
    );
    this._placeholderElement?.parentNode?.insertBefore(
      onetrustScript,
      this._placeholderElement
    );
    this._placeholderElement?.parentNode?.insertBefore(
      endComment,
      this._placeholderElement
    );
  }

  private removePlaceholderElement() {
    this._placeholderElement?.parentNode?.removeChild(this._placeholderElement);
  }

  public installOnetrust() {
    const onetrustScript = document.createElement('script');
    this.setScriptAttributes(onetrustScript);
    this.appendOptanonWrapperFunctionToWindow();
    const comments = this.createCommentsElements();

    this.insertElementsIntoDOM(onetrustScript, comments);
    this.removePlaceholderElement();
  }
}
