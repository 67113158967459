// @ts-nocheck
import { Context } from '@nuxt/types';
import isEmpty from 'lodash.isempty';

export default (context: Context): void => {
  const { $sentry: sentry, $config: config } = context;

  if (!isEmpty(sentry)) {
    const hub = sentry.getCurrentHub();
    if (hub) {
      const client = hub.getClient();
      if (client) {
        const options = client.getOptions();
        options.environment = config.ENVIRONMENT;
      }
    }

    sentry.configureScope((scope) => {
      scope.setExtras({
        config,
      });
    });
  }
};
