
import { defineComponent } from '@nuxtjs/composition-api';
import Icon from '@/components/icon/Icon.vue';

export default defineComponent({
  components: {
    Icon,
  },
  props: {
    quoteText: {
      type: Array,
      default: () => [],
    },
    quoteAuthor: {
      type: String,
      default: '',
    },
    quoteAuthorsTitle: {
      type: String,
      default: '',
    },
  },
});
