import { Context, Inject } from '@nuxt/types/app';
import { UnleashClient } from 'unleash-proxy-client';
import { createFeatureFlagStorageProvider } from '~/shared/utils/featureFlags/featureFlags.utils';

export default async function (ctx: Context, inject: Inject): Promise<void> {
  const { $config: config, store } = ctx;
  const unleash = new UnleashClient({
    url: config.UNLEASH_API_URL,
    clientKey: config.UNLEASH_API_TOKEN,
    appName: config.UNLEASH_APP_NAME,
    storageProvider: createFeatureFlagStorageProvider(store),
    environment: config.UNLEASH_API_ENVIRONMENT,
  });

  await unleash.start();
  const isFeatureEnabled = (featureFlagName: string): boolean => {
    return unleash.isEnabled(featureFlagName);
  };

  inject('isFeatureEnabled', isFeatureEnabled);
}
