import get from 'lodash.get';
import {
  RawErrorObject,
  ParsedErrorObject,
} from './responseErrorsParser.types';

const mapRawErrorMessages = (path: string, rawMessage: string) => {
  const customMessages: ParsedErrorObject = {
    '.body.postcode': 'Incorrect format for post / zip code',
    '.body.countryName': 'Does not match any country',
  };
  return customMessages[path] || rawMessage;
};

export const responseErrorParser = (error: object): ParsedErrorObject => {
  const errors = get(
    error,
    'response.data.data.errors',
    []
  ) as RawErrorObject[];

  const parsedErrors = errors.reduce<ParsedErrorObject>(
    (finalErrors, currentError) => {
      if (currentError) {
        return {
          ...finalErrors,
          [currentError.path]: mapRawErrorMessages(
            currentError.path,
            currentError.message
          ),
        };
      }
      return finalErrors;
    },
    {}
  );

  return parsedErrors;
};
