import compareVersions from 'compare-versions';
import {
  UnsupportedBrowserStatus,
  BrowserInfo,
} from './unsupported-browser.types';

const LOCAL_STORAGE_KEY = 'ols-unsupported-browser-status';

export const getUnsupportedBrowserStatus =
  (): UnsupportedBrowserStatus | null => {
    const value = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!value) {
      return null;
    }

    return value as UnsupportedBrowserStatus;
  };

export const setUnsupportedBrowserStatus = (
  status: UnsupportedBrowserStatus
) => {
  return localStorage.setItem(LOCAL_STORAGE_KEY, status.toString());
};

export const isUnsupportedBrowser = (browser: BrowserInfo): boolean => {
  if (browser.type === 'mobile') {
    return false;
  }

  switch (browser.name) {
    case 'Internet Explorer': {
      return true;
    }
    case 'Microsoft Edge': {
      const comparison = compareVersions(browser.version, '42');
      if (comparison >= 0) {
        return false;
      }
      return true;
    }
    case 'Safari': {
      const comparison = compareVersions(browser.version, '11.1');
      if (comparison >= 0) {
        return false;
      }
      return true;
    }
    default: {
      return false;
    }
  }
};
