
import { computed } from 'vue';
import { defineComponent } from '@nuxtjs/composition-api';
import { getIdFromUrl } from 'vue-youtube';
import { vueVimeoPlayer as VueVimeoPlayer } from 'vue-vimeo-player';

export default defineComponent({
  components: {
    VueVimeoPlayer,
  },
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
    isYoutubeVideo: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props) => {
    const videoId = computed(() => {
      return getIdFromUrl(props.videoUrl);
    });

    return {
      videoId,
    };
  },
});
