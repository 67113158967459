
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { CustomerLogo } from './CustomerLogos.types';

export default defineComponent({
  props: {
    logos: {
      type: Array as PropType<CustomerLogo[]>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    blurb: {
      type: String,
      default: '',
    },
  },
});
