import { ExpanderState, StoreActionContext } from '@/shared/types/store';

export const state = (): ExpanderState => ({
  activeExpanderId: '',
});

export const mutations = {
  SET_ACTIVE_EXPANDER_ID(state: ExpanderState, expanderId: string) {
    state.activeExpanderId = expanderId;
  },
};

export const actions = {
  setActiveExpanderId(context: StoreActionContext, expanderId: string): void {
    context.commit('SET_ACTIVE_EXPANDER_ID', expanderId);
  },
};
