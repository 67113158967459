
import { defineComponent, computed, PropType } from '@nuxtjs/composition-api';
import { Document as ContentfulDocument } from '@contentful/rich-text-types';
import RichTextVueRenderer from 'contentful-rich-text-vue-renderer';
import {
  renderRichTextDocumentToHtml,
  renderRichTextDocumentToPlainText,
} from './richText.utils';
import { richTextDocumentToVueConfig } from './richTextVue.utils';

export default defineComponent({
  components: {
    RichTextVueRenderer,
  },
  props: {
    richTextDocument: {
      type: Object as PropType<ContentfulDocument>,
      required: true,
    },
    renderPlainText: {
      type: Boolean,
      default: false,
    },
    renderRichTextVue: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props) => {
    const renderedDocument = computed(() => {
      if (props.renderRichTextVue) {
        return richTextDocumentToVueConfig();
      }

      if (props.renderPlainText) {
        return renderRichTextDocumentToPlainText(props.richTextDocument);
      }

      return renderRichTextDocumentToHtml(props.richTextDocument);
    });

    return { renderedDocument };
  },
});
