
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import Icon from '@/components/icon/Icon.vue';
import { WebsiteBannerStyle } from '@/shared/types/store/maintenanceMessage/maintenanceMessage.types';
type BannerType = 'Info' | 'Success' | 'Alert';

export default defineComponent({
  components: {
    Icon,
  },
  props: {
    type: {
      type: String as PropType<BannerType>,
      default: 'Info',
    },
    bannerStyling: {
      type: String as PropType<WebsiteBannerStyle>,
      default: WebsiteBannerStyle.MAINTENANCE_ALERT,
    },
  },
  setup: () => {
    return {
      WebsiteBannerStyle,
    };
  },
});
