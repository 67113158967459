
import { defineComponent } from '@nuxtjs/composition-api';
import BaseButton from '@/components/buttons/base-button/BaseButton.vue';

export default defineComponent({
  components: {
    BaseButton,
  },
  props: {
    componentTag: {
      type: String,
      default: 'div',
    },
    imageSrc: {
      type: String,
      default: '',
    },
    altText: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: 'Read more',
    },
    buttonLink: {
      type: String,
      default: '#',
    },
    isButtonEnabled: {
      type: Boolean,
      default: false,
    },
    useImageAsFirstItem: {
      type: Boolean,
      default: true,
    },
    htmlContent: {
      type: String,
      default: '',
    },
  },
  setup: (props) => {
    const imageLocationStyles = props.useImageAsFirstItem
      ? 'md:flex-row'
      : 'md:flex-row-reverse';

    return { imageLocationStyles };
  },
});
