import { Context } from '@nuxt/types';

export default function (context: Context): void {
  const CLIENT_DOMAIN_HEADER = 'client-domain';
  const { $axios, $config } = context;

  $axios.onError((error) => {
    const code = error?.response?.status;

    if (code === 400) {
      // do something on 400 errors
      // render specific page ?
    }
  });
  if (process.server && $config.API_URL_SSR) {
    $axios.setBaseURL($config.API_URL_SSR);
    $axios.setHeader(CLIENT_DOMAIN_HEADER, context.req.headers.host);
  } else {
    $axios.setBaseURL($config.API_URL_BROWSER);
    $axios.setHeader(CLIENT_DOMAIN_HEADER, window.location.origin);
  }
}
