export type MaintenanceMessageState = {
  message: string | null;
  websiteBannerStyling: WebsiteBannerStyle;
};

export enum WebsiteBannerStyle {
  MAINTENANCE_ALERT = 'Maintenance Alert',
  SALE = 'Sale',
  NEWS = 'News',
  WEBINAR = 'Webinar',
}
