import { PersonalDetails } from '../common/common.types';

export type LiteProfileInfo = {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
};

type GigyaPreferencesOptions = {
  isConsentGranted: boolean;
};

export type GigyaPreferences = {
  [key: string]: GigyaPreferencesOptions;
};

export type InitRegistrationResponse = {
  regToken: string;
};

export type GigyaResponse = {
  errorCode: number;
  status: 'FAIL' | 'OK';
};

export enum GigyaCommonErrorCodesEnum {
  INVALID_REG_TOKEN = 400006,
  FORBIDDEN = 403042,
  INVALID_RESET_PASSWORD_LINK = 403025,
}

export type GigyaAdditionalEventsHandlers = {
  [key: string]: (e: any) => void;
};

export type GigyaUserProfile = {
  firstName: string;
  lastName: string;
  email: string;
  organization: string;
  country: string;
  work: {
    company: string;
  };
};

export type GigyaUserPreferences = {
  declarationOfCommitment: {
    cambridgeadvance: {
      isConsentGranted: boolean;
    };
  };
  declarationOfEnglishLanguage: {
    cambridgeadvance: {
      isConsentGranted: boolean;
    };
  };
  terms: {
    cambridgeadvance: {
      isConsentGranted: boolean;
    };
  };
  privacy: {
    cambridgeadvance2: {
      isConsentGranted: boolean;
    };
  };
};

export type GigyaUserSubscriptions = {
  marketingCAO: {
    email: {
      isSubscribed: boolean;
      tags: string[];
    };
  };
  marketingSubjectCAO: {
    email: {
      isSubscribed: boolean;
      tags: string[];
    };
  };
};

export type GigyaUserData = {
  leadSource: string;
};

export type GigyaUserAccount = {
  profile: GigyaUserProfile;
  subscriptions: GigyaUserSubscriptions;
  preferences: GigyaUserPreferences;
  data: GigyaUserData;
};

export type GigyaEventOnSubmit = {
  formModel: {
    profile: GigyaUserProfile;
    subscriptions: GigyaUserSubscriptions;
    preferences: GigyaPreferences;
    data: GigyaUserData;
  };
};

export type GigyaEventDataOnLogin = {
  profile: {
    email: string;
  };
};

export type GigyaEventAfterSubmit = GigyaUserAccount & {
  response: {
    UID: string;
  };
};

export type GigyaEventAfterValidation = {
  eventName: string;
  formErrors: object;
  validationErrors: object;
};

export type GigyaScreensetPayload = {
  screenSet: string;
  redirectURL?: string;
  containerID: string;
  startScreen?: string;
  onAfterScreenLoad?: () => void;
  onBeforeScreenLoad?: () => void;
  onBeforeSubmit?: () => void;
  onSubmit?: (event: GigyaEventOnSubmit) => void;
  onAfterSubmit?: (event: GigyaEventAfterSubmit) => void;
  onBeforeValidation?: () => void;
  onAfterValidation?: (event: GigyaEventAfterValidation) => void;
  onError?: (event: GigyaResponse) => void;
};

export type GigyaPersonalDetailsPreferences = {
  privacyPolicyAccepted: boolean;
};

export type GigyaPersonalDetailsPayload = {
  personalDetails: PersonalDetails;
  preferences: GigyaPersonalDetailsPreferences;
};
