import dayjs from 'dayjs';
import { DateObject } from './dateFormatter.types';

export const getCourseRangeDate = (date: DateObject): string | undefined => {
  const isValidStartDate = dayjs(date.startDate).isValid();
  const isValidEndDate = dayjs(date.endDate).isValid();

  if (!isValidStartDate || !isValidEndDate) return;

  const isYearEqual =
    dayjs(date.startDate).year() === dayjs(date.endDate).year();

  if (!isYearEqual) {
    return `${dayjs(date.startDate).format('DD MMM YYYY')} - ${dayjs(
      date.endDate
    ).format('DD MMM YYYY')}`;
  }

  return `${dayjs(date.startDate).format('DD MMM')} - ${dayjs(
    date.endDate
  ).format('DD MMM YYYY')}`;
};

export const formatCardDate = (date: string): string | undefined => {
  if (!date || !dayjs(date).isValid()) return;

  const dateFormat =
    dayjs(date).format('YYYY-MM') === date ? 'MMM YYYY' : 'D MMM YYYY';

  return dayjs(date).format(dateFormat);
};
