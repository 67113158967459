import { Context } from '@nuxt/types';
import { MarketingSubdomainRedirector } from './marketingSubdomainRedirector';
import { marketingNeutralPageNames } from './marketingNeutralPageNames';

export default (context: Context) => {
  const hostWithCaoMarketing = context.$config.SITE_URL;
  const hostWithoutCaoMarketing = context.$config.SITE_SERVICES_URL;
  if (!hostWithCaoMarketing || !hostWithoutCaoMarketing) return;

  const marketingSubdomainRedirector = new MarketingSubdomainRedirector(
    {
      marketingHost: hostWithCaoMarketing,
      nonMarketingHost: hostWithoutCaoMarketing,
      marketingNeutralPageNames,
    },
    context
  );

  marketingSubdomainRedirector.handle();
};
