
import { defineComponent } from '@nuxtjs/composition-api';
import Icon from '@/components/icon/Icon.vue';

export default defineComponent({
  components: {
    Icon,
  },
  props: {
    messageType: {
      type: String,
      required: true,
    },
    roleType: {
      type: String,
      default: 'alert',
    },
    isCardError: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
  },
  setup: (props) => {
    const isError = props.messageType === 'error';
    const isSuccess = props.messageType === 'success';
    const isInfo = props.messageType === 'info';
    const isPrompt = props.messageType === 'prompt';

    return { isError, isSuccess, isInfo, isPrompt };
  },
});
