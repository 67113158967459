
import { defineComponent, computed } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    buttonTag: {
      type: String,
      default: 'button',
    },
    buttonType: {
      type: String,
      default: 'primary',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props) => {
    const buttonDynamicStyles = computed(() => ({
      'bg-button-primary-default hover:bg-button-primary-hover text-white visited:text-white':
        props.buttonType === 'primary' && !props.dark,
      'bg-button-primary-dark hover:bg-button-primary-dark-hover text-black visited:text-black':
        props.buttonType === 'primary' && props.dark,
      'bg-button-primary-disabled hover:bg-button-primary-disabled text-button-primary-text-disabled':
        props.buttonType === 'primary' && props.disabled,
      'border-2 border-button-secondary hover:bg-button-secondary-hover text-dark-grey visited:text-dark-grey':
        props.buttonType === 'secondary' && !props.dark,
      'border-2 border-button-secondary-dark hover:bg-button-secondary-dark-hover text-white visited:text-white':
        props.buttonType === 'secondary' && props.dark,
      'border-2 border-button-secondary-disabled hover:bg-transparent text-button-secondary-text-disabled':
        props.buttonType === 'secondary' && props.disabled && !props.dark,
      'border-2 border-button-secondary-dark-disabled hover:bg-transparent text-button-secondary-dark-text-disabled':
        props.buttonType === 'secondary' && props.disabled && props.dark,
      'cursor-not-allowed pointer-events-none': props.disabled,
    }));

    return { buttonDynamicStyles };
  },
});
