
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { Document as ContentfulDocument } from '@contentful/rich-text-types';
import BannerIcon from './BannerIcon.vue';
import RichText from '@/components/contentful/rich-text/RichText.vue';
import { WebsiteBannerStyle } from '@/shared/types/store/maintenanceMessage/maintenanceMessage.types';
type BannerType = 'Info' | 'Success' | 'Alert';

export default defineComponent({
  components: {
    RichText,
    BannerIcon,
  },
  props: {
    type: {
      type: String as PropType<BannerType>,
      default: 'Info',
    },
    richTextDocument: {
      type: Object as PropType<ContentfulDocument>,
      required: true,
    },
    bannerStyling: {
      type: String as PropType<WebsiteBannerStyle>,
      default: WebsiteBannerStyle.MAINTENANCE_ALERT,
    },
  },
  setup: () => {
    return {
      WebsiteBannerStyle,
    };
  },
});
