
import {
  defineComponent,
  getCurrentInstance,
  nextTick,
  onMounted,
  onUnmounted,
  PropType,
  ref,
} from '@nuxtjs/composition-api';
import { useSwipe } from '@vueuse/core';
import { TestimonialsProps } from './testimonials.types';
import SectionContentContainer from '@/components/section-content-container/SectionContentContainer.vue';
import TestimonialCard from '~/components/cards/testimonial-card/TestimonialCard.vue';
import Icon from '~/components/icon/Icon.vue';

export default defineComponent({
  components: {
    SectionContentContainer,
    TestimonialCard,
    Icon,
  },
  props: {
    content: {
      type: Object as PropType<TestimonialsProps>,
      required: true,
    },
    titleHeadingClass: {
      type: String,
      default: '',
    },
    shouldOpenLinkInNewTab: {
      type: Boolean,
      default: false,
    },
    isB2BTestimonials: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const testimalsLength = props.content.testimonials.length;
    const activeIndex = ref(0);
    const instance = getCurrentInstance();
    const testimonialHeight = ref(0);
    const swipeContainer = ref<HTMLElement | null>(null);

    const getTestimonialHeight = async () => {
      testimonialHeight.value = 0;
      await nextTick();
      const allRefs = instance?.proxy?.$refs as Record<string, Vue[]>;
      const testimonialCardsMaxheight = Object.entries(allRefs).reduce<number>(
        (acc, next) => {
          const [key, value] = next;

          if (!key.includes('testimonial-')) {
            return acc;
          }

          const height = (value as Vue[])?.[0]?.$el?.clientHeight;
          if (height && height > acc) {
            return height;
          }

          return acc;
        },
        0
      );

      testimonialHeight.value = testimonialCardsMaxheight;
    };

    onMounted(() => {
      getTestimonialHeight();
      window?.addEventListener('resize', getTestimonialHeight);
    });

    onUnmounted(() => {
      window?.removeEventListener('resize', getTestimonialHeight);
    });

    const setActive = (index: number) => {
      activeIndex.value = index;
    };

    const prevSlide = () => {
      activeIndex.value =
        (activeIndex.value - 1 + testimalsLength) % testimalsLength;
    };

    const nextSlide = () => {
      activeIndex.value = (activeIndex.value + 1) % testimalsLength;
    };

    const getActiveTestimonial = () => {
      const currentTestimnial = props.content.testimonials[activeIndex.value];
      if (!currentTestimnial) {
        return '';
      }
      const position = activeIndex.value + 1;
      const total = testimalsLength;
      return `testimonial ${position} of ${total}`;
    };

    useSwipe(swipeContainer, {
      onSwipeEnd(_, direction) {
        if (direction === 'right') return prevSlide();
        if (direction === 'left') return nextSlide();
      },
    });

    return {
      activeIndex,
      setActive,
      prevSlide,
      nextSlide,
      getActiveTestimonial,
      testimonialHeight,
      swipeContainer,
    };
  },
});
