
import { getCurrentInstance } from 'vue';
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { ZENDESK_HELP_CENTRE } from '@/shared/constants';

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      default: '#',
    },
    useRedirect: {
      type: Boolean,
      default: false,
    },
    featureName: {
      type: String,
      default: '',
    },
    isNewFooterStyle: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props) => {
    const instance = getCurrentInstance();
    const { $config } = instance!.proxy;
    const siteUrl = $config?.SITE_URL;
    const redirectUrl = computed(() => {
      return props.useRedirect
        ? ZENDESK_HELP_CENTRE
        : `${siteUrl}${props.path}`;
    });

    return {
      redirectUrl,
    };
  },
});
