import { useRoute, computed } from '@nuxtjs/composition-api';

const pagesWithoutCaoBranding = [
  '/account/activation',
  '/account/complete-registration',
  '/account/create-password',
  '/account/forgotten-password',
  '/account/reset-password',
  '/login',
  '/login/consent',
  '/login/proxy',
  '/login/proxy/error',
  '/courses/:courseSlug/book-a-place/:bookingSlug?',
  '/courses/:courseSlug/enquiry-form',
];

export const useCaoBranding = () => {
  const route = useRoute();

  const hasCaoBranding = computed(
    () =>
      !route.value.matched.find((matchedRoute) =>
        pagesWithoutCaoBranding.includes(matchedRoute.path)
      ) && !!route.value.matched.length
  );

  return {
    hasCaoBranding,
  };
};
