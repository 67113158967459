import isEmpty from 'lodash.isempty';
import { CourseProduct } from '@/shared/types';

export const checkCourseProductsAvailability = (
  courseProducts: CourseProduct[]
): boolean => {
  return !isEmpty(
    courseProducts.find(
      (courseProduct) =>
        !courseProduct.isFullyBooked &&
        !courseProduct.isArchived &&
        !courseProduct.isEnrolmentDeadlineExceeded &&
        !courseProduct.isInPreview
    )
  );
};

export const checkCourseCanBeBooked = (
  courseProducts: CourseProduct[]
): boolean => {
  return !isEmpty(
    courseProducts.find(
      (courseProduct) =>
        !courseProduct.isArchived &&
        !courseProduct.isEnrolmentDeadlineExceeded &&
        !courseProduct.isFullyBooked
    )
  );
};
