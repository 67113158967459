
import { getCurrentInstance } from 'vue';
import { defineComponent, useMeta } from '@nuxtjs/composition-api';
import Navigation from '@/components/navigation/Navigation.vue';
import Footer from '@/components/footer/Footer.vue';
import UnsupportedBrowser from '@/components/unsupported-browser/UnsupportedBrowser.vue';
import PreviewInfoBanner from '@/components/banner/PreviewInfoBanner.vue';
import { OnetrustInstallator } from '@/shared/utils/index';
import { useGtm } from '~/composables/gtm/useGtm';

const includeRealUserMonitoring = (env: string) => {
  if (env === 'prod') {
    const rumMOKey = 'cf742c5f41cd7a88600ae4c24f9481fe';
    if (
      process.browser &&
      window &&
      window.performance &&
      window.performance.timing &&
      window.performance.navigation
    ) {
      const monitoringScript = document.createElement('script');
      monitoringScript.async = true;
      monitoringScript.src = `//static.site24x7rum.eu/beacon/site24x7rum-min.js?appKey=${rumMOKey}`;
      document.getElementsByTagName('head')[0].appendChild(monitoringScript);
    }
  }
};

const includeOnetrust = (onetrustScriptId: string) => {
  if (!onetrustScriptId || !process.browser || !window || !document) {
    return;
  }

  const onetrustScriptUrl =
    'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js';
  const dataDocumentLanguageAttribute = 'true';
  const placeholderId = 'onetrust-placeholder';
  const onetrustInstallator = new OnetrustInstallator(
    onetrustScriptUrl,
    dataDocumentLanguageAttribute,
    onetrustScriptId,
    placeholderId,
    window,
    document
  );

  onetrustInstallator.installOnetrust();
};

export default defineComponent({
  components: {
    Navigation,
    UnsupportedBrowser,
    Footer,
    PreviewInfoBanner,
  },
  setup: () => {
    const instance = getCurrentInstance();
    const { installGtm } = useGtm();
    const { $store, $config } = instance!.proxy;
    const skipToContentHandler = (e: Event) => {
      e.preventDefault();
      const skipcontent = document.getElementById('skipcontent');

      window.setTimeout(
        () =>
          window.scroll({
            top: 0,
          }),
        0
      );
      skipcontent?.focus({
        preventScroll: true,
      });
    };

    const env = $config.ENVIRONMENT as string;
    const gtmId = $config.GTM_ID as string;
    const onetrustScriptId = $config.ONETRUST_SCRIPT_ID as string;

    includeRealUserMonitoring(env);
    installGtm(gtmId);
    includeOnetrust(onetrustScriptId);

    useMeta(() => ({
      meta: [
        {
          name: 'ols-service-version',
          content: $store.state?.serviceInfo?.version || '',
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `${$config.SITE_URL}${instance?.proxy.$route.path}`,
        },
      ],
      noscript: [
        {
          innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
          body: true,
        },
      ],
    }));

    return { skipToContentHandler };
  },
  head: {},
  watch: {
    $route() {
      if (process.browser) {
        const skipContentWrapper =
          document.getElementById('skipContentWrapper');
        skipContentWrapper?.focus();
      }
    },
  },
});
