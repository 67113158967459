
import { getCurrentInstance } from 'vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { renderRichTextDocumentToHtml } from '../contentful/rich-text/richText.utils';
import Banner from '@/components/banner/Banner.vue';
import { useCaoBranding } from '~/composables/branding/useCaoBranding';
import { WebsiteBannerStyle } from '@/shared/types/store/maintenanceMessage/maintenanceMessage.types';

export default defineComponent({
  components: { Banner },
  setup: () => {
    const instance = getCurrentInstance();
    const { $store } = instance!.proxy;

    const maintenanceMessage = $store.state.maintenanceMessage;
    const isMaintenanceMessageNotEmpty =
      maintenanceMessage.message &&
      !!renderRichTextDocumentToHtml(maintenanceMessage.message)
        .replace(/(<([^>]+)>)/gi, '')
        .trim() &&
      !!maintenanceMessage.websiteBannerStyling;

    const { hasCaoBranding } = useCaoBranding();

    const shouldDisplayBanner = computed(() => {
      return (
        isMaintenanceMessageNotEmpty &&
        (hasCaoBranding.value ||
          maintenanceMessage.websiteBannerStyling ===
            WebsiteBannerStyle.MAINTENANCE_ALERT)
      );
    });

    return {
      maintenanceMessage,
      shouldDisplayBanner,
    };
  },
});
