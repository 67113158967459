// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../graphics/tick-green.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".overview h2{font-size:1.625rem;line-height:2.375rem}.overview h2,.overview h3{font-weight:500;letter-spacing:.013rem;margin-bottom:1rem}.overview h3{font-size:1.375rem;line-height:1.625rem;margin-top:50px}.overview p{font-size:1.125rem;line-height:1.75rem;padding-top:30px}.overview li:first-child{padding-top:1.25rem}.overview li{list-style-position:inside;list-style-type:disc;padding-top:1rem}@media (min-width:1140px){.overview h2{font-size:1.875rem;line-height:auto}.overview h3{font-size:1.375rem;line-height:1.625rem}}.requirements h3{font-size:1.375rem;font-weight:500;letter-spacing:.013rem;line-height:1.625rem;margin-bottom:30px;margin-top:50px}.requirements ul{list-style-type:none;margin:0;padding:0}.requirements li{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0 0;font-size:1.125rem;line-height:1.75rem;line-height:1.25rem;line-height:30px;margin-bottom:1.25rem;margin-bottom:30px;min-height:30px;padding-left:40px}.requirements p{font-weight:300;padding-top:.25rem}.requirements b{font-weight:500}@media (min-width:1140px){.requirements h3{font-size:1.375rem;line-height:1.625rem}}.course-stage p{font-size:1.125rem;font-weight:300;line-height:1.75rem;padding-top:1rem}.course-stage b{font-weight:500}.course-stage h3{font-size:1.125rem;font-weight:500;line-height:1.75rem;margin-top:50px}.course-stage li{font-weight:300;list-style-position:inside;list-style-type:disc;padding-top:.5rem}@media (min-width:768px){.course-stage>*{padding-right:5rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
