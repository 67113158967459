import trim from 'lodash.trim';

export const truncate = (text: string, limit: number): string => {
  const textTrimed = text.trim();

  if (!text) {
    return '';
  }

  if (textTrimed.length <= limit) {
    return text;
  }

  return `${textTrimed.slice(0, limit).trim()}...`;
};

export const trimText = (text?: string, chars: string = ', '): string => {
  if (!text) return '';

  return trim(text, chars);
};
