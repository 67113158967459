type FormatPriceOpts = {
  showFractions: boolean;
};
const CurrencyPrefixes: Record<string, string> = {
  USD: 'US$',
  AUD: 'AU$',
  CAD: 'CAD',
  EUR: '€',
  GBP: '£',
};
// reference => https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
export const formatPriceNumber = (price: number) => {
  return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export function formatPrice(
  price: number,
  currency: string,
  opts?: FormatPriceOpts
) {
  const prefix = CurrencyPrefixes[currency];
  const showFractions = opts?.showFractions || false;
  if (!prefix) {
    throw new Error('Unsupported currency');
  }

  const formattedPriceNumber = formatPriceNumber(price);
  const finalPriceNumber = showFractions
    ? formattedPriceNumber
    : formattedPriceNumber.slice(0, price.toString().length + 1);

  return `${prefix}${finalPriceNumber}`;
}
