
import { getCurrentInstance } from 'vue';
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { externalAPI } from '@/config/api.config';
import ActionButton from '@/components/buttons/action-button/ActionButton.vue';

export default defineComponent({
  components: {
    ActionButton,
  },
  props: {
    includeBookACall: {
      type: Boolean,
      default: false,
    },
    includeCourseBrochure: {
      type: Boolean,
      default: false,
    },
    bookACallTitle: {
      type: String,
      default: '',
    },
    bookACallDescription: {
      type: String,
      default: '',
    },
    courseBrochureTitle: {
      type: String,
      default: '',
    },
    courseBrochureDescription: {
      type: String,
      default: '',
    },
    brochureLinkingUrl: {
      type: String,
      default: '',
    },
    slug: {
      type: String,
      default: '',
    },
    isWiderContainer: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props) => {
    const instance = getCurrentInstance();
    const {
      $config: { CALENDLY_URL },
    } = instance!.proxy;

    const requestACallLink = CALENDLY_URL
      ? `${CALENDLY_URL}${externalAPI.calendly.bookACall}`
      : '';

    const brochureLink = computed(
      () => props.brochureLinkingUrl || `/courses/${props.slug}#course-brochure`
    );

    return {
      brochureLink,
      requestACallLink,
    };
  },
});
