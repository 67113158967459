var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('SectionContentContainer',{class:{
    'section-spacing-with-navbar': !_vm.isB2BTestimonials,
    'pb-100px': _vm.isB2BTestimonials,
  }},[(!_vm.isB2BTestimonials)?_c('h2',{staticClass:"secondary-section-heading",class:_vm.titleHeadingClass},[_vm._v("\n    What our learners are saying\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"ml-0 mt-60px"},[_c('div',{ref:"swipeContainer",staticClass:"testimonials md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-y-30px md:gap-x-30px relative"},_vm._l((_vm.content.testimonials),function(testimonial,index){return _c('TestimonialCard',{key:index,ref:`testimonial-${index}`,refInFor:true,class:index === _vm.activeIndex
            ? 'block active'
            : 'sm:absolute md:block sm:invisible',style:({
          height: _vm.testimonialHeight ? `${_vm.testimonialHeight}px` : 'auto',
        }),attrs:{"card-border-color":_vm.content.cardsBorderColor,"testimonial":testimonial,"should-open-link-in-new-tab":_vm.shouldOpenLinkInNewTab}})}),1),_vm._v(" "),_c('div',{staticClass:"flex flex-row gap-60px md:hidden justify-center mt-30px"},[_c('button',{attrs:{"data-testid":"view-previous-testimonial","aria-label":"View previous testimonial"},on:{"click":_vm.prevSlide}},[_c('Icon',{attrs:{"name":"prev-arrow-black"}})],1),_vm._v(" "),_c('div',{staticClass:"border rounded-full h-22px bg-white flex gap-16px px-16px py-6px",attrs:{"tabindex":"0","role":"slider","aria-label":"Testimonials","aria-valuenow":"0","aria-valuetext":_vm.getActiveTestimonial()},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;return _vm.prevSlide.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 2)return null;return _vm.nextSlide.apply(null, arguments)}]}},_vm._l((_vm.content.testimonials),function(testimonial,index){return _c('span',{key:index,staticClass:"w-10px h-10px rounded-full border cursor-pointer outline hover:outline-focus",class:index === _vm.activeIndex ? 'bg-green active' : 'bg-white',attrs:{"id":`stepper-item-${index + 1}`,"tabindex":"-1","data-testid":`stepper-item-${index + 1}`},on:{"click":function($event){return _vm.setActive(index)}}})}),0),_vm._v(" "),_c('button',{attrs:{"data-testid":"view-next-testimonial","aria-label":"View next testimonial"},on:{"click":_vm.nextSlide}},[_c('Icon',{attrs:{"name":"next-arrow-black"}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }