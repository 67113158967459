import { BlogState, StoreActionContext } from '@/shared/types/store';

export const state = (): BlogState => ({
  activeCategorySlug: 'all-posts',
  page: 1,
  slug: '',
  searchPostSlug: '',
  searchPage: 1,
});

export const mutations = {
  SET_ACTIVE_BLOG_CATEGORY(state: BlogState, categorySlug: string) {
    state.activeCategorySlug = categorySlug;
    state.page = 1;
  },
  SET_BLOG_SEARCH_PREV_POST_SLUG(state: BlogState, slug: string) {
    state.searchPostSlug = slug;
  },
  SET_BLOG_SEARCH_PAGE_NO(state: BlogState, page: number) {
    state.searchPage = page;
  },
  SET_BLOG_PAGE_NO(state: BlogState, page: number) {
    state.page = page;
  },
  SET_BLOG_PREV_POST_SLUG(state: BlogState, slug: string) {
    state.slug = slug;
  },
  RESET_BLOG_HOMEPAGE_SCROLL_STATE(state: BlogState) {
    state.slug = 'slug';
    state.activeCategorySlug = 'all-posts';
    state.page = 1;
  },
  RESET_BLOG_SEARCHPAGE_SCROLL_STATE(state: BlogState) {
    state.searchPage = 1;
    state.searchPostSlug = '';
  },
};

export const actions = {
  setActiveBlogCategory(
    context: StoreActionContext,
    categorySlug: string
  ): void {
    context.commit('SET_ACTIVE_BLOG_CATEGORY', categorySlug);
  },
  setBlogPageNo(context: StoreActionContext, page: number): void {
    context.commit('SET_BLOG_PAGE_NO', page);
  },
  setBlogSearchPageNo(context: StoreActionContext, page: number): void {
    context.commit('SET_BLOG_SEARCH_PAGE_NO', page);
  },
  setPrevPostSlug(context: StoreActionContext, slug: string): void {
    context.commit('SET_BLOG_PREV_POST_SLUG', slug);
  },
  setPrevSearchPostSlug(context: StoreActionContext, slug: string): void {
    context.commit('SET_BLOG_SEARCH_PREV_POST_SLUG', slug);
  },
  resetBlogHomepageScrollState(context: StoreActionContext): void {
    context.commit('RESET_BLOG_HOMEPAGE_SCROLL_STATE');
  },
  resetBlogSearchPageScrollState(context: StoreActionContext): void {
    context.commit('RESET_BLOG_SEARCHPAGE_SCROLL_STATE');
  },
};
