
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { CourseTestimonial } from '@/shared/types/course/course.types';
import QuoteIcon from '@/components/icon/components/QuoteIcon.vue';

export default defineComponent({
  components: {
    QuoteIcon,
  },
  props: {
    componentTag: {
      type: String,
      default: 'div',
    },
    testimonial: {
      type: Object as PropType<CourseTestimonial>,
      required: true,
    },
    cardBorderColor: {
      type: String,
      required: true,
    },
    shouldOpenLinkInNewTab: {
      type: Boolean,
      default: false,
    },
  },
});
