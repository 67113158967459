
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    to: {
      type: String,
      required: true,
    },
    isDefaultAnchor: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props) => {
    return { fullPath: props.to };
  },
});
