
import { getCurrentInstance } from 'vue';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    reverseColor: {
      type: Boolean,
      default: false,
    },
    hasCaoBranding: {
      type: Boolean,
      required: true,
    },
    disableLink: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props) => {
    const instance = getCurrentInstance();
    const { $config } = instance!.proxy;
    const siteUrl = $config?.SITE_URL;

    const AOLogoVariant = props.reverseColor ? 'ao-white' : 'ao-black';
    const UCLogoVariant = props.reverseColor ? 'uc-online-white' : 'uc-online';

    return { AOLogoVariant, UCLogoVariant, siteUrl };
  },
});
