
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
    altText: {
      type: String,
      default: '',
    },
  },
});
