import { Ref } from '@nuxtjs/composition-api';
import { UtmTags } from '../utm/utm.types';
import {
  EcommerceBookingStatusEnum,
  EcommerceBillingInformation,
  EcommerceCourseDetails,
  EcommercePaymentRedirect,
  BillingAddress,
  PersonalDetails,
  ProductCost,
  RequestError,
} from '@/shared/types';
import { LearnerDetails } from '~/shared/types/common/common.types';

export enum BasketPaymentMethod {
  CARD = 'card',
  PAY_BY_QUOTE = 'quote',
  PAYPAL = 'paypal',
}

export type EcommerceSessionDiscount = {
  appliedDiscountCode?: string;
};

// Session

export type EcommerceSessionResponse = {
  currency: string;
  countryCode: string;
  completedSteps: EcommerceBookingStatusEnum[];
  isUserSessionInitialized: boolean;
  courseId: string;
  productSku: string;
  billingAddress: BillingAddress;
  orderId: number;
  konakartSessionId: number;
  paymentMethod?: BasketPaymentMethod;
  discount?: EcommerceSessionDiscount;
};

export type EcommerceSessionData = {
  ecommerceSession: Ref<EcommerceSessionResponse | undefined>;
  isEcommerceSessionError: Ref<boolean | undefined>;
  isEcommerceSessionEmpty: Ref<boolean | undefined>;
  initializeEcommerceSession: (courseId: string) => Promise<void>;
  updateEcommerceSession: () => Promise<void>;
};

// Basket

export type EcommerceBillingAddressResponse = {
  error?: RequestError;
};

export type EcommerceBillingAddressData = {
  isBillingAddressAssignmentPending: Ref<boolean | undefined>;
  isBillingAddressAssignmentPendingError: Ref<boolean | undefined>;
  assignAddress: (
    payload: BillingAddress
  ) => Promise<EcommerceBillingAddressResponse>;
  productCost: Ref<ProductCost | undefined>;
};

export type OrderCreationFMAuthResponse = {
  referenceId: string;
  accessToken: string;
  deviceDataCollectionUrl: string;
  sessionId: string;
};

type Currency = {
  code: string;
  name: string;
  symbolLeft: string;
  symbolRight: string;
};

type BasketItem = {
  id: number;
  rateId: number;
  sku: string;
  quantity: number;
  priceWithTax: number;
  unitPrice: number;
  unitPriceAsString: string;
};

export type OrderCreationResponse = {
  id: number;
  status: string;
  billingAddressId: number;
  deliveryAddressId: number;
  items: BasketItem[];
  totals: OrderTotals;
  currency: Currency;
};

export type PaypalSessionResponse = {
  token: string;
  requestID: string;
  orderId: string;
};

type OrderDiscount = {
  discountValue: number;
  discountValueAsString: string;
  discountDescription: string;
};

type OrderTotals = {
  total: number;
  gross: number;
  net: number;
  tax: number;
  discount: OrderDiscount[];
};

export type OrderCreationPayload = {
  paymentMethod: BasketPaymentMethod;
  token?: string;
};

export type EcommerceOrderCreationData = {
  isOrderCreationPending: Ref<boolean | undefined>;
  isOrderCreationPendingError: Ref<boolean | undefined>;
  createOrder: (
    payload: OrderCreationPayload
  ) => Promise<
    | OrderCreationFMAuthResponse
    | OrderCreationResponse
    | PaypalSessionResponse
    | undefined
  >;
  orderCreationResponse: Ref<
    | OrderCreationFMAuthResponse
    | OrderCreationResponse
    | PaypalSessionResponse
    | undefined
  >;
};

export type EcommerceFlexPublicKeyData = {
  isFlexPublicKeyPending: Ref<boolean | undefined>;
  isFlexPublicKeyPendingError: Ref<boolean | undefined>;
  generateKey: () => Promise<string | undefined>;
  publicKey: Ref<string | undefined>;
};

export type PayerAuthData = {
  reasonCode: number;
  messagePaymentError?: string;
  messagePaymentErrorNextStep?: string;
  isAuthenticationRequired?: string;
  stepUpUrl?: string;
  stepUpToken?: string;
  merchantDefinedData?: string;
  status?: string;
  processOrderUrl?: string;
};

export type EcommercePayerAuthData = {
  isPayerAuthPending: Ref<boolean | undefined>;
  isPayerAuthPendingError: Ref<boolean | undefined>;
  callPayerAuth: () => Promise<PayerAuthData | undefined>;
  payerAuthData: Ref<PayerAuthData | undefined>;
};

export type EcommerceValidateActivationCodeResponse = {
  productSku: string;
};

export type EcommerceBasketDataResponse = {
  productSku: string;
  billingAddress?: BillingAddress;
};

export type EcommerceBasketData = {
  isBasketDataPending: Ref<boolean | undefined>;
  isBasketDataError: Ref<boolean | undefined>;
  basketData: Ref<EcommerceBasketDataResponse | undefined>;
};

// Order

export type EcommerceReviewOrderResponse = {
  personalDetails: PersonalDetails;
  billingInformation: EcommerceBillingInformation;
  courseDetails: EcommerceCourseDetails;
  courseCost: ProductCost;
  amIparticipating?: boolean;
  participants?: LearnerDetails[];
};

export type EcommerceReviewOrderData = {
  reviewOrderData: Ref<EcommerceReviewOrderResponse | undefined>;
  isReviewOrderDataPending: Ref<boolean | undefined>;
  isReviewOrderDataError: Ref<boolean | undefined>;
};

export type EcommercePaymentOrderResponse = {
  id: number;
  status: string;
  paymentRedirect?: EcommercePaymentRedirect;
};

export type EcommerceOrderConfirmationResponse = {
  buyer: PersonalDetails;
  course: EcommerceCourseDetails;
  amIparticipating: boolean;
  participants: PersonalDetails[];
  orderNumber?: number;
  currency?: string;
  value?: number;
  price?: number;
  quantity?: number;
  discount?: number;
  productSku?: string;
};

export type EcommerceOrderConfirmationData = {
  isConfirmationPending: Ref<boolean | undefined>;
  isConfirmationError: Ref<boolean | undefined>;
  orderConfirmation: Ref<EcommerceOrderConfirmationResponse | undefined>;
};

export type EcommercePreferences = {
  marketingConsentAccepted: boolean;
};

export type EcommercePersonalDetailsData = {
  personalDetails: PersonalDetails;
  preferences: EcommercePreferences;
  amIparticipating: boolean;
  participants: LearnerDetails[];
};

export type EcommercePersonalDetailsPayload = {
  UID: string;
  personalDetails: PersonalDetails;
  preferences: EcommercePreferences;
  utmValues?: UtmTags;
};

export type RegistrationUserPayload = {
  token: string;
  personalDetails: PersonalDetails;
  preferences: EcommercePreferences;
  productSku: string;
  utmValues?: UtmTags;
  data: {
    leadSource: string;
    eduelt: {
      instituteRole: Array<{
        institute: string;
      }>;
    };
  };
  amIparticipating: boolean;
  participants: LearnerDetails[];
};

export type EcommercePersonalDetailsResponse =
  EcommercePersonalDetailsData | null;

// Discount codes
export type EcommerceDiscountCodeResponse = {
  courseCost: ProductCost;
};

export type EcommerceDiscountCodeData = {
  setDiscountCode: (code: string) => Promise<void>;
  removeDiscountCode: () => Promise<void>;
  discountCodeData: Ref<EcommerceDiscountCodeResponse | undefined>;
  isDiscountCodePending: Ref<boolean | undefined>;
  applyDiscountCodeError: Ref<string | undefined>;
  applyDiscountCodeSuccess: Ref<boolean | undefined>;
};

export enum EcommerceDiscountCodeError {
  INVALID = 'Promotional code not recognised',
  EXPIRED = 'Promotional code expired',
  NOT_YET_ACTIVE = 'Promotional code not yet active',
}
